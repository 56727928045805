import { useEffect, useState } from 'react';

export type Orientations = 'mobile' | 'tablet' | 'desktop';

export enum BreakpointsEnum {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export type Breakpoints = `${BreakpointsEnum}`;

export const mediaQueryBreakpoints = {
  [BreakpointsEnum.mobile]: 767,
  [BreakpointsEnum.tablet]: 1199,
  [BreakpointsEnum.desktop]: 1439,
};

export const useBreakpoints = (): Breakpoints => {
  const [breakpoint, setBreakpoint] = useState(BreakpointsEnum.desktop);

  const onResize = () => {
    const windowWidth = document.documentElement.clientWidth;
    if (windowWidth <= mediaQueryBreakpoints.mobile) {
      setBreakpoint(BreakpointsEnum.mobile);
    }
    if (
      windowWidth > mediaQueryBreakpoints.mobile &&
      windowWidth < mediaQueryBreakpoints.tablet
    ) {
      setBreakpoint(BreakpointsEnum.tablet);
    }
    if (windowWidth >= mediaQueryBreakpoints.tablet) {
      setBreakpoint(BreakpointsEnum.desktop);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    if (typeof window !== 'undefined') {
      onResize();
    }
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return breakpoint;
};
