import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <div className={styles.footerCollumn}>
          <h4 className={styles.footerTitle}>Компания</h4>
          <ul>
            <li>
              <Link to='/about'>О нас</Link>
            </li>
            <li>
              <Link to='/privacy'>Политика конфиденциальности</Link>
            </li>
          </ul>
        </div>

        <div className={styles.footerCollumn}>
          <h4 className={styles.footerTitle}>Информация</h4>
          <ul>
            <li>
              <Link to='/#faq'>FAQ</Link>
            </li>

            <li>
              <Link to='/offer'>Договор-оферта</Link>
            </li>

            <li>
              <a
                href='https://t.me/Edya_support'
                target='_blank'
                rel='noreferrer'
              >
                Поддержка
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.footerCollumn}>
          <h4 className={styles.footerTitle}>Оплата</h4>
          <div className={styles.payWrap}>
            <img
              className={styles.payImgCard}
              src='./images/icons/payments.png'
            />
            <img
              className={styles.payImgCard}
              src='./images/icons/payments1.png'
            />
            <img
              className={styles.payImgCard}
              src='./images/icons/payments2.png'
            />
            <img className={styles.payImgQiwi} src='./images/icons/qiwi.png' />
          </div>
        </div>

        <div className={styles.footerCollumn}>
          <h4 className={styles.footerTitle}>Социальные сети</h4>
          <div className={styles.socialWrap}>
            <a href='https://t.me/edya_robot' className={styles.telegram}>
              <FontAwesomeIcon icon={faTelegram} />
            </a>
          </div>

          <Link to='/'>
            <img
              className={styles.footerLogo}
              src='./images/logoMain.png'
              alt='logoMain'
            />
          </Link>
        </div>
      </div>

      <div className={styles.copyright}>
        <p>
          {`Этот сайт защищен reCAPTCHA и Google, `}
          <a
            className={styles.copyrightLink}
            href='https://policies.google.com/privacy'
            target='_blank'
            rel='noreferrer'
          >
            Политикой конфиденциальности
          </a>
          {` и `}
          <a
            className={styles.copyrightLink}
            href='https://policies.google.com/terms'
            target='_blank'
            rel='noreferrer'
          >
            Условиями использования Google
          </a>
        </p>
        <p>© Copyright 2023 Edya</p>
      </div>
    </footer>
  );
};

export default Footer;
