import classNames from 'classnames';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

import { useClickOutside } from 'hooks/useClickOutside';
import { useAppDispatch } from 'store/store';
import { editChat, getDialogs } from 'store/services/dialogs/actions';
import LoaderDots from 'components/Loaders/LoaderDots';

import styles from './DialogsMenu.module.scss';

type TProps = {
  name: string;
  id: string;
  isActive: string;
  setActiveChat: (id: string) => void;
};

const DialogsMenu: React.FC<TProps> = ({
  id,
  name,
  isActive,
  setActiveChat,
}) => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [isLoading, setIsLoading] = useState(false);

  const buttonEditRef = useRef(null);

  useClickOutside(buttonEditRef, () => setIsEditing(false));

  const editTitleChat = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsEditing(true);
  };

  const handleDeleteIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setActiveChat(null);

    dispatch(editChat({ id: isActive, is_active: false })).then(() => {
      dispatch(getDialogs());
    });
  };

  const saveEditTitle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsLoading(true);

    dispatch(editChat({ id: isActive, name: editedName })).then(() => {
      dispatch(getDialogs());
      setIsLoading(false);
    });
    setIsEditing(false);
  };

  const cancelEditTitle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditing(false);
  };

  const handleActiveChat = () => {
    setActiveChat(id);
  };

  return (
    <div className={styles.chats} ref={buttonEditRef}>
      <div
        onClick={handleActiveChat}
        className={classNames(styles.chat, styles.setting, styles.md, {
          [styles.activeChat]: isActive,
        })}
      >
        <div className={styles.iconChatMessage}>
          <FontAwesomeIcon icon={faMessage} />
        </div>
        {isEditing ? (
          <input
            autoFocus
            type='text'
            className={styles.titleChatInput}
            value={editedName}
            onChange={event => setEditedName(event.target.value)}
            onClick={event => event.stopPropagation()}
          />
        ) : (
          <div className={styles.titleChat}>
            {isLoading ? <LoaderDots type='dialogs' /> : editedName}
          </div>
        )}

        {isActive && (
          <>
            <div className={styles.iconChatEdit} onClick={editTitleChat}>
              <FontAwesomeIcon icon={faPencil} />
            </div>
            {isEditing && (
              <div className={styles.buttonWrap}>
                <button className={styles.editButton} onClick={saveEditTitle}>
                  Редактировать
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={cancelEditTitle}
                >
                  Отменить
                </button>
              </div>
            )}
            <div
              className={styles.iconChatDelete}
              onClick={handleDeleteIconClick}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DialogsMenu;
