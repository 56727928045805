import { isString } from 'utils/helpers';

export const urls = {
  api: {
    auth: {
      register: {
        post: '/user/register/',
      },
      login: {
        post: '/auth/token/',
      },
    },
    prices: {
      subscription: {
        get: '/subscription/types',
        post: '/subscription',
      },
    },
    user: {
      get: '/user/current/',
      patch: '/user/current/',
      put: '/user/current/',
    },
    chat: {
      get: '/chat/',
      post: '/chat/',
      put: '/chat/:id/',
      patch: '/chat/:id/',
    },
    message: {
      get: '/message/',
      post: '/message/',
    },
    subscription: {
      get: '/subscription',
    },
    imageType: {
      get: '/image/model-types/',
    },
    image: {
      get: '/image/:id/',
      post: '/image/',
    },
    forgotPassword: {
      post: '/user/request-password-changing/',
    },
    changePassword: {
      post: '/user/change-password/',
    },
  },
  upload: {
    post: `${process.env.REACT_APP_BACKEND_URL}upload/v1/user/avatar/`,
  },
  survey: {
    post: `/survey/comming-soon-survey/`,
  },
  telegram: {
    auth: {
      post: '/telegram/token/',
    },
    register: {
      post: '/telegram/register/',
    },
  },
};

export const getUrlWithParams = (
  url: string,
  params: Record<string, string | number> = {},
  isQuery = false
) => {
  if (!isString(url)) {
    throw new Error('url must be string');
  }
  let urlWithParams = url;
  Object.keys(params).forEach(param => {
    if (!params[param]) {
      throw new Error(`Not found param ${param} in url pattern ${url}`);
    }
    if (isQuery) {
      urlWithParams = urlWithParams.replace(
        `:${param}`,
        `?${param}=${params[param].toString()}`
      );
    } else {
      urlWithParams = urlWithParams.replace(
        `:${param}`,
        `${params[param].toString()}`
      );
    }
  });
  return urlWithParams;
};
