import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import Loader from 'components/Loaders/Loader';
import { changePassword } from 'utils/auth';
import notify from 'components/Notifications';
import { useQuery } from 'hooks/useQuery';

import { defaultValues, IPasswordState, validationSchema } from './formUtils';
import Button from '../../Button';
import styles from '../ForwardPassword.module.scss';

const ForgotPasswordEmail: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const query = useQuery();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = ({ password }: IPasswordState) => {
    setIsLoading(true);
    changePassword({ password, token: query.get('token') })
      .then(() => {
        setIsLoading(false);
        navigate('/authorization');
        setTimeout(() => {
          notify({
            type: 'success',
            message: 'Пароль успешно изменен',
          });
        }, 1);
      })
      .catch(error => {
        setErrorMessage(error);
        setIsLoading(false);
      });
  };

  const errorMessageWrap = () => {
    const firstErrorMessage = Object.values(errorMessage)[0];
    return firstErrorMessage ? (
      <p className={styles.errorMessage}>{firstErrorMessage}</p>
    ) : null;
  };

  return !isLoading ? (
    <div className={styles.forgotContainer}>
      <FormProvider {...methods}>
        <form
          className={styles.forgotForm}
          onSubmit={methods.handleSubmit(submit)}
        >
          <p className={styles.forgotText}>Введите новый пароль</p>
          <ControlledInput
            name='password'
            placeholder='Введите новый пароль'
            type='password'
          />
          <ControlledInput
            name='repeatPassword'
            placeholder='Повторите новый пароль'
            type='password'
          />

          {errorMessageWrap()}
          <Button
            size='md'
            variant='autorization'
            textButton='authorizationText'
            type='submit'
          >
            сбросить пароль
          </Button>
        </form>
      </FormProvider>
    </div>
  ) : (
    <Loader size='standard' />
  );
};

export default ForgotPasswordEmail;
