import { memo, useCallback, useEffect, useRef } from 'react';
import ClipboardJS from 'clipboard';

import { getMessages, getNextMessages } from 'store/services/dialogs/actions';
import { getUser } from 'store/services/user/actions';
import { useAppDispatch, useAppSelector } from 'store/store';
import useDebounce from 'utils/debounce';
import notify from 'components/Notifications';
import Loader from 'components/Loaders/Loader';
import LoaderDots from 'components/Loaders/LoaderDots';

import { Message } from './components/Message';
import styles from './Messages.module.scss';
import 'highlight.js/styles/base16/material-darker.css';
import 'katex/dist/katex.min.css';

type TProps = {
  id: string;
};

const Messages: React.FC<TProps> = memo(({ id }) => {
  const dispatch = useAppDispatch();

  const { messages, nextUrl, isLoading } = useAppSelector(
    state => state.dialog
  );
  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    id &&
      dispatch(getMessages({ id }))
        .unwrap()
        .catch(error => {
          console.error('ошибка при получении сообщений', error);
        });
    dispatch(getUser());
  }, [id]);

  const handleScroll = () => () => {
    if (messagesRef.current) {
      const { scrollTop } = messagesRef.current;
      const negativeHeight =
        -messagesRef.current.scrollHeight + messagesRef.current.clientHeight;

      if (scrollTop <= negativeHeight + 20 && nextUrl) {
        dispatch(getNextMessages({ id, nextUrl }));
      }
    }
  };

  const handleScrollCallback = useCallback(handleScroll(), [
    messagesRef,
    dispatch,
    id,
    nextUrl,
  ]);
  const debouncedHandleScroll = useDebounce(handleScrollCallback, 200);

  useEffect(() => {
    const clipboard = new ClipboardJS('.copyButton');

    clipboard.on('success', e => {
      notify({
        type: 'success',
        message: 'Код скопирован в буффер обмена',
      });
      e.clearSelection();
    });

    return () => {
      clipboard.destroy();
    };
  }, []);

  return messages?.[id] ? (
    <>
      <div
        className={styles.messagesWrap}
        onScroll={debouncedHandleScroll}
        ref={messagesRef}
      >
        {messages?.[id].map((message, i) => (
          <Message key={i} message={message} />
        ))}
      </div>
      {isLoading ? <LoaderDots type='messages' /> : null}
    </>
  ) : (
    <div className={styles.loader}>
      <Loader size='standard' />
    </div>
  );
});

Messages.displayName = 'Messages';
export default Messages;
