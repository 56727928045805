import Recaptcha from 'components/Recaptcha';

import styles from './Layout.module.scss';

type TProps = {
  children: React.ReactNode;
};

const Layout: React.FC<TProps> = ({ children }) => {
  return (
    <div className={styles.layout}>
      {children}
      <Recaptcha />
    </div>
  );
};

export default Layout;
