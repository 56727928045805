import { useEffect } from 'react';

import styles from './AboutUs.module.scss';

const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.aboutUs}>
      <p className={styles.aboutTitle}>о нас</p>
      <div className={styles.aboutWrap}>
        <p>Привет, мы — команда Edya.</p>
        <p>
          Наш проект — это разработка в области нейросетей. Мы страстно увлечены
          искусственным интеллектом и его потенциалом для творчества и
          инноваций.
        </p>
        <p>
          Наша команда стремится создать продукт, который поможет людям в
          различных сферах жизни, от искусства и дизайна до науки и бизнеса.
        </p>
        <p>
          Одной из наших ключевых технологий является GPT — нейросеть, обученная
          на огромном объеме текстовых данных, которая способна генерировать
          качественный и креативный контент. Мы также предоставляем доступ к
          моделям для генерации изображений, которые могут быть использованы в
          различных областях, включая рекламу, игровую индустрию, визуальные
          эффекты, бизнес.
        </p>
        <p>
          Мы стремимся быть на переднем крае технологических инноваций и
          предлагать продукты, которые вдохновляют и улучшают жизнь людей. Мы
          гордимся нашей работой и стремимся делиться нашими достижениями и
          знаниями с сообществом.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
