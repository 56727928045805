import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store/store';
import { getDialogs, getModelTypes } from 'store/services/dialogs/actions';
import { getUser } from 'store/services/user/actions';
import Button from 'components/Button';
import Loader from 'components/Loaders/Loader';
import { useClickOutside } from 'hooks/useClickOutside';

import DialogsMenu from './components/DialogsMenu';
import SettingUser from './components/SettingUser';
import { CreateMessageArea } from './components/CreateMessageArea';
import styles from './DialogBox.module.scss';

const DialogBox: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { dialogs, isDialogsLoading } = useAppSelector(state => state.dialog);

  const dialogMenuRef = useRef(null);

  const [activeChat, setActiveChat] = useState<string | null>(null);
  const [isBurgerShown, setIsBurgerShown] = useState(false);

  useEffect(() => {
    dispatch(getDialogs());
    dispatch(getUser());
  }, []);

  useClickOutside(dialogMenuRef, () => setIsBurgerShown(false));

  const handleClick = useCallback(
    id => {
      setActiveChat(id);
    },
    [setActiveChat]
  );

  const dialogList = useMemo(() => {
    return dialogs
      .map(({ name, id }) => (
        <DialogsMenu
          id={id}
          key={id}
          name={name}
          isActive={activeChat === id ? activeChat : ''}
          setActiveChat={handleClick}
        />
      ))
      .reverse();
  }, [dialogs, activeChat, handleClick]);

  const handleBurgerShown = () => {
    setIsBurgerShown(!isBurgerShown);
  };

  const createNewDialog = () => {
    setIsBurgerShown(false);
    if (activeChat) {
      dispatch(getUser());
    }
    handleClick(null);
  };

  useEffect(() => {
    dispatch(getModelTypes());
  }, []);

  return (
    <>
      <div className={styles.dialogBox}>
        <div ref={dialogMenuRef}>
          <div
            onClick={handleBurgerShown}
            className={
              isBurgerShown
                ? `${styles.burger} ${styles.navigationOpen}`
                : styles.burger
            }
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div
            className={
              isBurgerShown
                ? `${styles.settings} ${styles.navigationOpen}`
                : styles.settings
            }
          >
            <div className={styles.settingNewChat}>
              <Button
                onClick={createNewDialog}
                size='md-dlg'
                variant='newDialog'
                textButton='newDialogText'
              >
                + новый чат
              </Button>

              {dialogs.length ? (
                <div className={styles.chatsWrap}>{dialogList}</div>
              ) : null}
              {isDialogsLoading ? <Loader size='small' /> : null}
            </div>

            <SettingUser />
          </div>
        </div>
        <CreateMessageArea
          activeChat={activeChat}
          handleActiveChatClick={handleClick}
        />
      </div>
    </>
  );
});

DialogBox.displayName = 'DialogBox';
export default DialogBox;
