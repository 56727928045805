import { Controller, useFormContext } from 'react-hook-form';

import styles from './ControlledGroupRadio.module.scss';

export type TProps = {
  name: string;
  options: { label: string; value: string }[];
};

export const ControlledGroupRadio = ({ name, options }: TProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <>
          {options.map(option => (
            <div key={option.value} className={styles.formGroup}>
              <input
                {...field}
                type='radio'
                id={`${name}-${option.value}`}
                value={option.value}
                className={styles.customCheckbox}
                checked={field.value === option.value}
                onChange={() => field.onChange(option.value)}
              />
              <label
                htmlFor={`${name}-${option.value}`}
                className={styles.checkboxLabel}
              >
                {option.label}
              </label>
            </div>
          ))}
          {fieldState.error && (
            <p className={styles.errorMessage}>{fieldState.error.message}</p>
          )}
        </>
      )}
      control={control}
    />
  );
};
