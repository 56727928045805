import Yup from 'utils/yup';

export interface ILoginState {
  username: string;
  password: string;
  remember: boolean;
}

export const defaultValues: ILoginState = {
  username: '',
  password: '',
  remember: false,
};

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email('Введите корректный email')
    .trim()
    .required('Введите email'),
  password: Yup.string()
    .trim()
    .required('Введите пароль')
    .min(8)
    .matches(
      /^([!@#$%^&*()\-_=+{};:,<.>a-zA-Z0-9])*$/,
      'Пароль может содержать только латинские буквы, цифры и спецсимволы(!@#$%^&*()-_=+{};:,<.>)'
    ),
});
