import Footer from 'components/Footer';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Line from 'components/Line';
import { SeoMetaTitles } from 'components/SeoMetaTitles';
import SuccessfulPayment from 'components/SuccessfulPayment';

const SuccessfulPaymentPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <Layout>
        <Header />
        <SuccessfulPayment />
      </Layout>
      <Line />
      <Layout>
        <Footer />
      </Layout>
    </>
  );
};

export default SuccessfulPaymentPage;
