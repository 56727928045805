import classNames from 'classnames';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import styles from './ControlledInput.module.scss';

export type TProps = {
  name: string;
  type?: 'text' | 'password';
  placeholder?: string;
};

export const ControlledInput = ({
  name,
  type = 'text',
  placeholder = 'Введите значение',
}: TProps) => {
  const { control } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <>
          <div className={styles.inputWrap}>
            <input
              type={showPassword ? 'text' : type}
              placeholder={placeholder}
              {...field}
              className={classNames(styles.input, {
                [styles.error]: fieldState.error,
              })}
            />
            {type === 'password' && (
              <div className={styles.iconEyase} onClick={handleTogglePassword}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </div>
            )}
          </div>

          {fieldState.error && (
            <p className={styles.errorMessage}>{fieldState.error.message}</p>
          )}
        </>
      )}
      control={control}
    />
  );
};
