import { useEffect } from 'react';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.privacyPolicy}>
      <p className={styles.privacyTitle}>Политика конфиденциальности</p>
      <p
        className={styles.privacyWrap}
      >{`Обработка персональных данных в сервисе Edya осуществляется с соблюдением всех требований Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных».

1. Определение целей обработки персональных данных
1.1. Цели обработки персональных данных включают:
- обеспечение работы сервиса;
- ведение аналитики.

2. Сбор персональных данных
2.1. Персональные данные пользователей собираются только при их согласии и только в объеме, необходимом для обеспечения работы сервиса.
2.2. Персональные данные не передаются третьим лицам за исключением случаев, когда это необходимо для обеспечения работы сервиса (например, для обработки платежей).
2.3. Поставщик может обрабатывать следующие персональные данные Пользователя, такие как, имя, фамилия, электронный адрес.
2.4. На сайте происходит сбор и обработка обезличенных данных о Пользователе с помощью сервисов интернет-статистики Яндекс Метрика.

3. Принципы обработки персональных данных
3.1. Обработка персональных данных осуществляется на законной и справедливой основе.
3.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.

4. Порядок хранения, передачи и других видов обработки персональных данных
4.1. Поставщик хранит персональные данные пользователей на защищенных серверах.
4.2. Поставщик принимает меры для защиты персональных данных от несанкционированного доступа, использования, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.
4.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Поставщику на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.

5. Использование персональных данных
5.1. Персональные данные пользователей используются только для обеспечения работы сервиса и ведения аналитики.
5.2. Поставщик не передает персональные данные третьим лицам без согласия пользователей, за исключением случаев, когда это необходимо для обеспечения работы сервиса (например, для обработки платежей).

6. Удаление персональных данных
6.1. Пользователь может запросить удаление своих персональных данных, предоставленных при регистрации и использовании сервиса.
6.2. Поставщик удаляет персональные данные пользователя в течение 30 дней с момента получения запроса.

7. Ответственность
7.1. Поставщик несет ответственность за сохранность и защиту персональных данных пользователей.
7.2. Поставщик обязуется немедленно уведомить пользователей и компетентные органы о случаях несанкционированного доступа к персональным данным.

8. Заключительные положения
8.1. Обработка персональных данных в сервисе Edya осуществляется в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных».
8.2. Все споры, связанные с обработкой персональных данных, разрешаются в соответствии с законодательством Российской Федерации.`}</p>
    </div>
  );
};

export default PrivacyPolicy;
