import { useNavigate } from 'react-router-dom';
import TelegramLoginButton from 'react-telegram-login';

import { telegramLogin, telegramRegister } from 'utils/auth';

import styles from './TelegramWidget.module.scss';

export const TelegramWidget = () => {
  const navigate = useNavigate();

  const botName = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  const handleTelegramResponse = async ({ id, first_name, last_name }) => {
    try {
      await telegramLogin({ id });
      navigate('/profile');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        try {
          const { telegram_id } = await telegramRegister({
            id,
            first_name,
            last_name,
          });
          await telegramLogin({ id: telegram_id });
          navigate('/profile');
        } catch (registerError) {
          console.error(registerError, 'Ошибка при регистрации пользователя');
        }
      } else {
        console.error(error, 'Ошибка при авторизации пользователя');
      }
    }
  };

  return (
    <div className={styles.container}>
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        botName={botName}
        lang='ru'
        buttonSize='large'
        cornerRadius={10}
        requestAccess='write'
      />
    </div>
  );
};
