import AboutUs from 'components/AboutUs';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Line from 'components/Line';
import { SeoMetaTitles } from 'components/SeoMetaTitles';
const AboutUsPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <Layout>
        <Header />
        <AboutUs />
      </Layout>
      <Line />
      <Layout>
        <Footer />
      </Layout>
    </>
  );
};

export default AboutUsPage;
