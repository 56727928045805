import { createSlice } from '@reduxjs/toolkit';

import { editUser, getUser, getSubscription } from './actions';
import { IUser } from './interfaces';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    first_name: '',
    last_name: '',
    email: '',
    remaining_requests: 0,
    total_messages: 0,
    active_days: 0,
    avatar_url: '',
    isUserInfoLoading: false,
    subscription: [],
    captchaToken: null,
    telegram_id: null,
    is_telegram_user: null,
  } as IUser,
  reducers: {
    setCaptchaToken: (state, action) => {
      state.captchaToken = action.payload;
    },
    clearUser: state => {
      const { captchaToken } = state;
      state = {
        captchaToken,
        first_name: '',
        last_name: '',
        email: '',
        remaining_requests: 0,
        total_messages: 0,
        active_days: 0,
        avatar_url: '',
        telegram_id: null,
        is_telegram_user: null,
        isUserInfoLoading: false,
        subscription: [],
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      return { ...state, ...payload, isUserInfoLoading: false };
    });
    builder.addCase(getUser.pending, state => {
      state.isUserInfoLoading = true;
    });
    builder.addCase(getUser.rejected, state => {
      state.isUserInfoLoading = false;
    });
    builder.addCase(editUser.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          first_name: payload.first_name,
          last_name: payload.last_name,
        };
      }
    });
    builder.addCase(editUser.rejected, state => {
      state.isUserInfoLoading = false;
    });
    builder.addCase(getSubscription.fulfilled, (state, { payload }) => {
      state.subscription = [...payload];
    });
    builder.addCase(getSubscription.pending, state => {
      state.isUserInfoLoading = true;
    });
    builder.addCase(getSubscription.rejected, state => {
      state.isUserInfoLoading = false;
    });
  },
});

export const { setCaptchaToken, clearUser } = userSlice.actions;
