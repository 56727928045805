import axios from 'axios';

import { getAccessToken } from './token';
import { refreshToken } from './auth';

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}api/v1/`,
});

apiClient.interceptors.request.use(
  config => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Accept-Language'] = 'ru';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        const token = getAccessToken();
        if (token) {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return apiClient(originalRequest);
        }
      } catch (error) {
        console.error('Error refreshing access token', error);
      }
    }
    return Promise.reject(error);
  }
);
