import { useState, Fragment } from 'react';

import { questions } from './constants';
import styles from './FAQ.module.scss';

const FAQ: React.FC = () => {
  const [answerShow, setAnswerShow] = useState(new Set());
  const handleClick = (question: string) => {
    if (answerShow.has(question)) {
      const copyState = new Set(answerShow);
      copyState.delete(question);
      setAnswerShow(copyState);
    } else {
      const copyState = new Set(answerShow);
      copyState.add(question);
      setAnswerShow(copyState);
    }
  };

  return (
    <div className={styles.faq}>
      <div id='faq' className={styles.faqTitle}>
        F.A.Q
      </div>
      <div className={styles.questions}>
        {questions.map(({ question, answer }) => (
          <Fragment key={question}>
            <div
              className={styles.question}
              onClick={() => handleClick(question)}
            >
              <p className={styles.faqText}>{question}</p>
              <div className={styles.iconWrap}>
                <img
                  className={
                    answerShow.has(question)
                      ? `${styles.faqIcon} ${styles.rotate}`
                      : styles.faqIcon
                  }
                  src='/images/icons/arrowRight.png'
                  alt='arrow-right'
                />
              </div>
            </div>
            <div
              className={
                answerShow.has(question)
                  ? `${styles.answer} ${styles.show}`
                  : styles.answer
              }
            >
              <p className={styles.textAnswer}>{answer}</p>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
