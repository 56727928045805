import { Controller, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { useAppSelector } from 'store/store';

import styles from './ControlledTextarea.module.scss';

export type TProps = {
  name: string;
  type?: 'text' | 'password';
  placeholder?: string;
  onSendMessage: () => void;
};

export const ControlledTextarea = ({ name, onSendMessage }: TProps) => {
  const { control } = useFormContext();

  const { slug, mode } = useAppSelector(state => state.dialog);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key, currentTarget } = event;
    if (key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const message = currentTarget.value.trim();
      if (message !== '') {
        onSendMessage();
      }
    }
  };

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <TextareaAutosize
          {...field}
          minRows={1}
          maxRows={7}
          placeholder={
            mode === 'text'
              ? 'Спросить у ассистента...'
              : `Cгенерировать изображение с помощью ${slug}...`
          }
          className={styles.sendMessageInput}
          onKeyDown={handleKeyDown}
        />
      )}
      control={control}
    />
  );
};
