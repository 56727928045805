import Yup from 'utils/yup';

import { IEditFormValues } from './Profile.types';

const passwordValidation = Yup.string()
  .trim()
  .min(8, 'Пароль должен быть не короче 8 символов')
  .matches(
    /^([!@#$%^&*()\-_=+{};:,<.>a-zA-Z0-9])*$/,
    'Пароль может содержать только латинские буквы, цифры и спецсимволы(!@#$%^&*()-_=+{};:,<.>)'
  )
  .required('Это поле обязательно');

export const validationSchema: any = Yup.lazy((values: IEditFormValues) => {
  const { oldPassword, password, repeatPassword } = values;

  if (oldPassword || password || repeatPassword) {
    return Yup.object().shape({
      firstName: Yup.string()
        .required('имя является обязательным полем')
        .min(2, 'Минимум 2 символа')
        .max(150, 'Максимум 150 символов'),
      lastName: Yup.string().max(150, 'Максимум 150 символов'),
      oldPassword: Yup.string()
        .trim()
        .min(8, 'Пароль должен быть не короче 8 символов')
        .nullable()
        .default(''),
      password: passwordValidation,
      repeatPassword: Yup.string()
        .test('isMatch', 'Пароли должны совпадать', function (value) {
          return value === this.parent.password;
        })
        .required('Это поле обязательно'),
    });
  } else {
    return Yup.object().shape({
      firstName: Yup.string()
        .required('имя является обязательным полем')
        .min(2, 'Минимум 2 символа')
        .max(150, 'Максимум 150 символов'),
      lastName: Yup.string().max(150, 'Максимум 150 символов'),
    });
  }
});

export const telegramValidationSchema: any = Yup.lazy(
  (values: IEditFormValues) => {
    const { email, password, repeatPassword } = values;

    if (email || password || repeatPassword) {
      return Yup.object().shape({
        firstName: Yup.string()
          .required('имя является обязательным полем')
          .min(2, 'Минимум 2 символа')
          .max(150, 'Максимум 150 символов'),
        lastName: Yup.string().max(150, 'Максимум 150 символов'),
        password: passwordValidation,
        repeatPassword: Yup.string()
          .test('isMatch', 'Пароли должны совпадать', function (value) {
            return value === this.parent.password;
          })
          .required('Это поле обязательно'),
        email: Yup.string().email().trim().required('Это поле обязательно'),
      });
    } else {
      return Yup.object().shape({
        firstName: Yup.string()
          .required('имя является обязательным полем')
          .min(2, 'Минимум 2 символа')
          .max(150, 'Максимум 150 символов'),
        lastName: Yup.string().max(150, 'Максимум 150 символов'),
      });
    }
  }
);
