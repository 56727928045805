import NotFound from 'components/404';
import { SeoMetaTitles } from 'components/SeoMetaTitles';

const NotFoundPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <NotFound />
    </>
  );
};

export default NotFoundPage;
