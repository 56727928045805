import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/store';
import { getSubscription } from 'store/services/user/actions';

import Button from '../../../Button';
import styles from './SettingUser.module.scss';

const SettingUser = () => {
  const dispatch = useAppDispatch();
  const { subscription } = useAppSelector(state => state.user);

  useEffect(() => {
    dispatch(getSubscription());
  }, []);

  const isPremium =
    subscription?.length && new Date(subscription[0].expired_at) > new Date();

  return (
    <div className={styles.settingUser}>
      {!isPremium ? (
        <Link to='/prices'>
          <Button size='sm-dlg' variant='setting' textButton='settingText'>
            <img
              className={styles.userImg}
              src='./images/icons/user.png'
              alt='user-profile'
            />
            обновление до Pro
          </Button>
        </Link>
      ) : null}

      <Link to='/profile'>
        <Button size='sm-dlg' variant='setting' textButton='settingText'>
          <img
            className={styles.exitImg}
            src='./images/icons/exit.png'
            alt='exit'
          />
          вернуться в профиль
        </Button>
      </Link>
    </div>
  );
};

export default SettingUser;
