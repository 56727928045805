import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';

import { ControlledTextarea } from 'components/ControlledTextarea/ControlledTextarea';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  createDialog,
  createMessage,
  generateImage,
  getDialogs,
  getDoneImage,
} from 'store/services/dialogs/actions';

import GenerationTypeSelection from '../GenerationTypeSelection';
import Messages from '../Messages';
import InitialElement from '../InitialElement';
import styles from './CreateMessageArea.module.scss';

export const CreateMessageArea = ({ activeChat, handleActiveChatClick }) => {
  const dispatch = useAppDispatch();

  const { slug, mode } = useAppSelector(state => state.dialog);
  const { remaining_requests } = useAppSelector(state => state.user);

  const methods = useForm({
    defaultValues: {
      body: '',
    },
  });

  const MemoizedMessages = useMemo(() => {
    return <Messages id={activeChat} />;
  }, [activeChat]);

  const submit = values => {
    const { body } = values;

    if (!activeChat && remaining_requests !== 0) {
      dispatch(createDialog({ name: body.slice(0, 100) })).then(
        ({ payload }) => {
          handleActiveChatClick((payload as { id: string }).id);
          sendMessage({ body, chat_id: (payload as { id: string }).id });
          methods.reset({ body: '' });
          dispatch(getDialogs());
        }
      );
    } else {
      sendMessage({ body, chat_id: String(activeChat) });
      methods.reset({ body: '' });
    }
  };

  const handleSendMessage = () => {
    const formData = methods.getValues();
    if (formData.body.trim() !== '') {
      methods.handleSubmit(submit)();
    }
  };

  const sendMessage = async ({ body, chat_id }) => {
    if (mode === 'image') {
      dispatch(generateImage({ promt: body, chat_id, slug })).then(
        generatedImage => {
          if ((generatedImage as any).payload) {
            getImageUrl({
              id: generatedImage.payload[0].id,
              body: generatedImage.payload[0].body,
              chat_id: generatedImage.payload[0].chat_id,
            });
          }
        }
      );
    } else {
      return dispatch(createMessage({ body, chat_id }));
    }
  };

  const getImageUrl = async ({ id, body, chat_id }) => {
    const { payload: data } = await dispatch(
      getDoneImage({ id, body, chat_id })
    );

    if (
      (data as { status: string })?.status &&
      (data as { status: string })?.status !== 'succeeded'
    ) {
      setTimeout(() => {
        getImageUrl({ id, body, chat_id });
      }, 4000);
    }
    return data;
  };

  return (
    <div className={styles.dialogArea}>
      {activeChat ? MemoizedMessages : <InitialElement />}

      <div className={styles.sendMessageWrap}>
        <GenerationTypeSelection />

        <FormProvider {...methods}>
          <form
            className={styles.sendMessage}
            onSubmit={methods.handleSubmit(submit)}
          >
            <ControlledTextarea name='body' onSendMessage={handleSendMessage} />
            <button
              className={styles.sendMessageBtn}
              type='button'
              onClick={handleSendMessage}
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                className={styles.sendMessageImg}
              />
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
