import * as yup from 'yup';

yup.setLocale({
  string: {
    min: 'Введите минимум ${min} символов',
    max: 'Введите не более ${max} символов',
    email: 'Введите корректный адрес электронной почты',
  },
});

export const passwordSchema = yup
  .string()
  .test('isMatch', 'Пароли должны совпадать', function (value) {
    return value === this.parent.password;
  });

export default yup;
