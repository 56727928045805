import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './NotificationsStyles.scss';

interface NotificationProps {
  type: 'success' | 'error' | 'warning';
  message: string;
}

const Notifications: React.FC<NotificationProps> = ({ type, message }) => {
  const notificationTypes = {
    success: {
      title: 'Успешно!',
      className: 'toastSuccess',
    },
    error: {
      title: 'Ошибка!',
      className: 'toastError',
    },
    warning: {
      title: 'Упс!',
      className: 'toastWarning',
    },
  };

  const { title, className } = notificationTypes[type];

  return (
    <div className={className}>
      <h4 className='toastTitle'>{title}</h4>
      <p className='toastMessage'>{message}</p>
    </div>
  );
};

interface NotifyOptions extends ToastOptions {
  type: 'success' | 'error' | 'warning';
  message: string;
}

const notify = ({ type, message, ...options }: NotifyOptions) => {
  toast[type](<Notifications type={type} message={message} />, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    ...options,
  });
};

export default notify;
