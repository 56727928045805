import { createAsyncThunk } from '@reduxjs/toolkit';
import * as jose from 'jose';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { GrantTypesEnum } from 'types/types';
import { setAccessToken, setRefreshToken } from 'utils/token';

import { IUser, IUserEditData, IUserSubscription } from './interfaces';

const secret = new TextEncoder().encode(
  process.env.REACT_APP_TELEGRAM_SITE_KEY
);

export const getUser = createAsyncThunk<IUser, void>(
  'getCurrentUser',
  async () => {
    const { data } = await apiClient.get(urls.api.user.get);
    return data;
  }
);

export const getSubscription = createAsyncThunk<IUserSubscription[], void>(
  'getSubscription',
  async () => {
    const { data } = await apiClient.get(urls.api.subscription.get);
    return data;
  }
);

export const editUser = createAsyncThunk<IUserEditData, Partial<IUserEditData>>(
  'editUser',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.patch(urls.api.user.patch, {
        ...params,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const telegramLogin = createAsyncThunk<any, any>(
  'telegramLogin',
  async ({ id }) => {
    try {
      const payload = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: GrantTypesEnum.Telegram,
        telegram_id: id,
      };

      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg: 'HS256' })
        .sign(secret);

      const response = await apiClient.post(urls.telegram.auth.post, payload, {
        headers: {
          Authorization: jwt,
        },
      });

      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      return response.data.telegram_id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
