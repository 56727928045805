import classNames from 'classnames';

import styles from './Button.module.scss';

type TProps = {
  children: React.ReactNode;
  size: 'sm' | 'sm-dlg' | 'md' | 'md-dlg' | 'md-lg' | 'lg' | 'xl';
  variant:
    | 'default'
    | 'autorization'
    | 'error'
    | 'profile'
    | 'profileExit'
    | 'newDialog'
    | 'setting';
  textButton:
    | 'headerText'
    | 'defaultText'
    | 'priceText'
    | 'authorizationText'
    | 'errorText'
    | 'profileText'
    | 'profileExitText'
    | 'premiumProfileText'
    | 'newDialogText'
    | 'settingText';
  onClick?: () => void;
  type?: 'submit' | 'button';
};

const Button: React.FC<TProps> = ({
  children,
  size,
  variant = 'default',
  textButton,
  type = 'button',
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles[size], styles[variant])}
      type={type}
    >
      <p className={styles[textButton]}>{children}</p>
    </button>
  );
};

export default Button;
