import { useState, useCallback } from 'react';
import { FileError, ErrorCode, useDropzone } from 'react-dropzone';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

import Loader from 'components/Loaders/Loader';
import notify from 'components/Notifications';
import { urls } from 'store/api';
import { getUser } from 'store/services/user/actions';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getAccessToken } from 'utils/token';

import './ImageUploaderStyles.css';

const ImageUploader = () => {
  const dispatch = useAppDispatch();
  const { avatar_url, isUserInfoLoading } = useAppSelector(state => state.user);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const onDrop = acceptedFiles => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    const token = getAccessToken();
    axios
      .post(urls.upload.post, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(getUser()).then(({ payload }) => {
          setAvatarUrl((payload as { avatar_url: string }).avatar_url);
        });
      })
      .catch(error => {
        console.error(error, 'Ошибка загрузки файла');
      });
  };

  const avatar = avatarUrl || avatar_url;

  const getMessage = useCallback((code: string) => {
    switch (code) {
      case ErrorCode.FileInvalidType:
        return 'Пожалуйста, загрузите фото допустимого формата (.jpg, .jpeg, .png)';
      case ErrorCode.FileTooLarge:
        return 'Файл слишком большой. Пожалуйста, используйте фото не больше 1Мб';
      case ErrorCode.TooManyFiles:
        return 'Вы можете загрузить только 1 файл';
      default:
        return code;
    }
  }, []);

  const validator = useCallback((file: File): FileError[] | null => {
    const errors: FileError[] = [];
    if (!/^image\/(png|jpe?g)$/.test(file.type)) {
      errors.push({
        message: ErrorCode.FileInvalidType,
        code: ErrorCode.FileInvalidType,
      });
    }
    if (file.size > 1048576) {
      errors.push({
        message: ErrorCode.FileTooLarge,
        code: ErrorCode.FileTooLarge,
      });
    }
    if (errors.length > 0) {
      return errors;
    }
    return null;
  }, []);

  const onDropRejected = useCallback(
    fileRejections => {
      const hasErrors = fileRejections.some(fileRejection => {
        return fileRejection.errors.some(error => {
          notify({
            type: 'error',
            message: `${getMessage(error.code)}`,
          });
          return true;
        });
      });
      return hasErrors;
    },
    [getMessage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
    },
    maxSize: 1048576,
    minSize: 0,
    maxFiles: 1,
    onDropRejected,
    validator,
  });

  return (
    <div className='imageUploader'>
      {isUserInfoLoading ? (
        <Loader size='small' />
      ) : (
        <div {...getRootProps()} className='wrapperImageUploader'>
          <input {...getInputProps()} />
          <img
            className='avatarImg'
            src={avatar ? avatar : './images/icons/userProfile.png'}
            alt='avatar-img'
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
