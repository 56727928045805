import Yup from 'utils/yup';

export enum PurposeEnum {
  integration = 'integration',
  personalUse = 'personal_use',
  supporting = 'edya_supporting',
}

export type TPurposeEnumType = `${PurposeEnum}`;

export interface ICommingState {
  name: string;
  company_name: string;
  company_description: string;
  email: string;
  purpose_of_use: TPurposeEnumType | null;
}

export const defaultValues: ICommingState = {
  name: '',
  company_name: '',
  company_description: '',
  email: '',
  purpose_of_use: null,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Введите имя'),
  company_name: Yup.string().trim().required('Введите название компании'),
  company_description: Yup.string().trim().required('Обязательное поле'),
  email: Yup.string()
    .email('Введите корректный email')
    .trim()
    .required('Введите email'),
  purpose_of_use: Yup.string().trim().required('Выберите хотя бы одну цель'),
});
