import { useEffect } from 'react';

import styles from './FailedPayment.module.scss';

const FailedPayment: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.successfulPayment}>
      <h2 className={styles.title}>К сожалению, оплата не удалась!</h2>
      <div className={styles.info}>
        <div className={styles.text}>
          Во время оплаты тарифа произошла ошибка
        </div>
        <div className={styles.text}>
          Попробуйте повторить операцию или обратитесь в нашу
          <a
            href='https://t.me/Edya_support'
            className={styles.text__accent}
            target='_blank'
            rel='noreferrer'
          >
            поддержку
          </a>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
