import DialogBox from 'components/DialogBox';
import MetrikaComponent from 'components/MetrikaComponent';
import { SeoMetaTitles } from 'components/SeoMetaTitles';
const DialogBoxPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <MetrikaComponent />
      <DialogBox />
    </>
  );
};

export default DialogBoxPage;
