import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import AboutUsPage from 'pages/AboutUsPage';
import AuthorizationPage from 'pages/AuthorizationPage';
import ContractOfferPage from 'pages/ContractOfferPage';
import DialogBoxPage from 'pages/DialogBoxPages';
import MainPage from 'pages/MainPage';
import PricesPage from 'pages/PricesPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import ProfilePage from 'pages/ProfilePage';
import RegistrationPage from 'pages/RegistrationPage';
import SuccessfulPaymentPage from 'pages/SuccessfulPaymentPage';
import FailedPaymentPage from 'pages/FailedPaymentPage';
import ForgotPasswordEmailPage from 'pages/ForgotPasswordEmailPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import ErrorBoundary from 'components/ErrorBoundary';
import CommingSoonPage from 'pages/CommingSoonPage';

import './styles/index.scss';

function App() {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/about',
      element: <AboutUsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/offer',
      element: <ContractOfferPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/privacy',
      element: <PrivacyPolicyPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/authorization',
      element: <AuthorizationPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/prices',
      element: <PricesPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/registration',
      element: <RegistrationPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/profile',
      element: (
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/dialog',
      element: (
        <PrivateRoute>
          <DialogBoxPage />
        </PrivateRoute>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/successful-payment',
      element: <SuccessfulPaymentPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/failed-payment',
      element: <FailedPaymentPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordEmailPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/change-password',
      element: <ChangePasswordPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/comming-soon',
      element: <CommingSoonPage />,
      errorElement: <ErrorBoundary />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
