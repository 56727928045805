import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './SuccessfulPayment.module.scss';

const SuccessfulPayment: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.successfulPayment}>
      <h2 className={styles.title}>Оплата успешно прошла!</h2>
      <div className={styles.info}>
        <div className={styles.text}>
          Данные о Вашей подписке будут отображены в
          <Link to={'/profile'} className={styles.text__accent}>
            профиле
          </Link>
        </div>
        <div className={styles.text}>
          Для начала работы с чатом – перейдите в
          <Link to={'/dialog'} className={styles.text__accent}>
            диалог
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPayment;
