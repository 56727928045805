import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import Loader from 'components/Loaders/Loader';
import { forgotPassword } from 'utils/auth';
import notify from 'components/Notifications';

import { defaultValues, IEmailState, validationSchema } from './formUtils';
import Button from '../../Button';
import styles from '../ForwardPassword.module.scss';

const ForgotPasswordEmail: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const username = methods.watch('username');

  useEffect(() => {
    if (!username) {
      setErrorMessage('');
    }
  }, [username]);

  const submit = (values: IEmailState) => {
    setIsLoading(true);
    forgotPassword({ ...values })
      .then(() => {
        setIsLoading(false);
        notify({
          type: 'success',
          message: 'Ожидайте письмо, которое придет на указанную почту. ',
        });
      })
      .catch(error => {
        setErrorMessage(error);
        setIsLoading(false);
      });
  };

  const errorMessageWrap = () => {
    const firstErrorMessage = Object.values(errorMessage)[0];
    return firstErrorMessage ? (
      <p className={styles.errorMessage}>{firstErrorMessage}</p>
    ) : null;
  };

  return !isLoading ? (
    <div className={styles.forgotContainer}>
      <FormProvider {...methods}>
        <form
          className={styles.forgotForm}
          onSubmit={methods.handleSubmit(submit)}
        >
          <div className={styles.forgotTextContainer}>
            <p className={styles.forgotText}>
              Для восстановления пароля – напишите свой email, на который придет
              письмо для сброса
            </p>
            <p className={styles.forgotText}>
              Если вы не видите письмо, проверьте папку «Спам»
            </p>
          </div>
          <ControlledInput placeholder='email пользователя' name='username' />
          {errorMessageWrap()}
          <Button
            size='md'
            variant='autorization'
            textButton='authorizationText'
            type='submit'
          >
            сбросить пароль
          </Button>
        </form>
      </FormProvider>
    </div>
  ) : (
    <Loader size='standard' />
  );
};

export default ForgotPasswordEmail;
