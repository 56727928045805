import classNames from 'classnames';

import styles from './Distinction.module.scss';

const Distinction: React.FC = () => {
  return (
    <div className={styles.distinctions}>
      <h1 className={styles.title}>В чём отличие EDYA от ChatGPT?</h1>

      <div className={styles.wrapper}>
        <div className={classNames(styles.line, styles.leftLine)} />
        <div className={styles.edya}>
          <div className={classNames(styles.display, styles.logoBlock)}>
            <img
              className={styles.logoEdya}
              src='/images/icons/logoEdyaWhite.png'
              alt='logo-edya-white'
            />
            <p className={styles.title}>EDYA</p>
          </div>
          <div className={styles.leftBlock}>
            <div className={styles.display}>
              <img
                className={classNames(styles.icon, styles.check)}
                src='/images/icons/checkMark.png'
                alt='check-mark'
              />
              <p className={styles.text}>
                Быстрые ответы без очереди и остановок
              </p>
            </div>
            <div className={styles.display}>
              <img
                className={classNames(styles.icon, styles.check)}
                src='/images/icons/checkMark.png'
                alt='check-mark'
              />
              <p className={styles.text}>
                Поддерживает русский язык и интерфейс
              </p>
            </div>
            <div className={styles.display}>
              <img
                className={classNames(styles.icon, styles.check)}
                src='/images/icons/checkMark.png'
                alt='check-mark'
              />
              <p className={styles.text}>
                Не нужен номер телефона и иностранная карта
              </p>
            </div>
            <div className={styles.display}>
              <img
                className={classNames(styles.icon, styles.check)}
                src='/images/icons/checkMark.png'
                alt='check-mark'
              />
              <p className={styles.text}>Доступ без VPN и выгодные тарифы</p>
            </div>
          </div>
        </div>

        <div className={classNames(styles.line, styles.rightLine)}></div>
        <div className={styles.chatGPT}>
          <div className={classNames(styles.display, styles.logoBlock)}>
            <p className={styles.title}>CHATGPT</p>
            <img
              className={styles.logoChat}
              src='/images/icons/chatGPT.svg'
              alt='logo-chatGPT-white'
            />
          </div>
          <div className={styles.rigthBlock}>
            <div className={styles.display}>
              <p className={styles.text}>
                Платная версия от 20$ с зарубежной карты
              </p>
              <img
                className={classNames(styles.icon, styles.cross)}
                src='/images/icons/cross.png'
                alt='cross'
              />
            </div>
            <div className={styles.display}>
              <p className={styles.text}>В пиковую загруженность не работает</p>
              <img
                className={classNames(styles.icon, styles.cross)}
                src='/images/icons/cross.png'
                alt='cross'
              />
            </div>
            <div className={styles.display}>
              <p className={styles.text}>Блокирует российских пользователей</p>
              <img
                className={classNames(styles.icon, styles.cross)}
                src='/images/icons/cross.png'
                alt='cross'
              />
            </div>
            <div className={styles.display}>
              <p className={styles.text}>Интерфейс на английском</p>
              <img
                className={classNames(styles.icon, styles.cross)}
                src='/images/icons/cross.png'
                alt='cross'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Distinction;
