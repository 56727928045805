import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCaptchaToken } from 'store/services/user/user.slice';

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (
        sitekey: string,
        options: { action: string }
      ) => Promise<string>;
    };
  }
}

const Recaptcha = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://www.google.com/recaptcha/api.js?render=' +
      process.env.REACT_APP_SITE_KEY;
    script.async = true;

    const handleRecaptchaToken = (token: string) => {
      dispatch(setCaptchaToken(token));
    };

    const loadRecaptchaScript = () => {
      const head = document.head || document.getElementsByTagName('head')[0];
      head.appendChild(script);
    };

    const executeRecaptcha = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_SITE_KEY, { action: 'homepage' })
          .then(handleRecaptchaToken);
      });
    };

    if (window.grecaptcha) {
      executeRecaptcha();
    } else {
      script.onload = executeRecaptcha;
      loadRecaptchaScript();
    }

    return () => {
      if (window.grecaptcha) {
        delete window.grecaptcha;
      }
      const head = document.head || document.getElementsByTagName('head')[0];
      head.removeChild(script);
    };
  }, [dispatch]);

  return <></>;
};

export default Recaptcha;
