import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { useAppSelector } from 'store/store';
import Button from 'components/Button';
import { ControlledProfileInput } from 'components/ControlledProfileInput/ControlledProfileInput';

import styles from './ProfileFields.module.scss';

export const ProfileFields = () => {
  const [changePassword, setChangePassword] = useState(false);

  const { email, is_telegram_user } = useAppSelector(state => state.user);
  const hasOnlyTelegram = is_telegram_user && !email;

  return (
    <>
      <ControlledProfileInput name='firstName' placeholder='Имя' />
      <ControlledProfileInput name='lastName' placeholder='Фамилия' />
      {!hasOnlyTelegram ? (
        <ControlledProfileInput
          name='email'
          placeholder='EMAIL'
          type='email'
          disabled={!is_telegram_user}
        />
      ) : null}

      {is_telegram_user ? (
        <div className={styles.basicTextBlock}>
          <p className={styles.basicText}>телеграм аккаунт привязан</p>
          <div className={styles.iconCheck}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
      ) : null}

      {changePassword ? (
        <>
          {hasOnlyTelegram ? (
            <ControlledProfileInput
              name='email'
              placeholder='EMAIL'
              type='email'
              disabled={!is_telegram_user}
            />
          ) : null}

          {email ? (
            <ControlledProfileInput
              name='oldPassword'
              placeholder='старый пароль'
              type='password'
            />
          ) : null}
          <ControlledProfileInput
            name='password'
            placeholder='новый пароль'
            type='password'
          />
          <ControlledProfileInput
            name='repeatPassword'
            placeholder='повторите новый пароль'
            type='password'
          />
        </>
      ) : null}

      <Button
        size='xl'
        variant='profile'
        textButton='profileText'
        type='submit'
      >
        cохранить изменения
      </Button>
      {!changePassword ? (
        <Button
          size='xl'
          variant='profile'
          textButton='profileText'
          onClick={() => setChangePassword(true)}
        >
          {hasOnlyTelegram ? 'привязать email' : 'изменить пароль'}
        </Button>
      ) : null}

      {changePassword ? (
        <Button
          size='xl'
          variant='profile'
          textButton='defaultText'
          onClick={() => {
            setChangePassword(false);
          }}
        >
          отмена
        </Button>
      ) : null}
    </>
  );
};
