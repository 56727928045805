import { Link } from 'react-router-dom';

import Button from '../Button';
import styles from './NotFound.module.scss';

const NotFound: React.FC = () => {
  return (
    <div className={styles.notFoundPage}>
      <div className={styles.notFoundWrap}>
        <p className={styles.notFoundTitle}>что-то пошло не так</p>
        <p className={styles.notFoundSubtitle}>запрашиваемый адрес не найден</p>
        <Link to='/'>
          <Button size='md-lg' variant='error' textButton='errorText'>
            вернуться
          </Button>
        </Link>
      </div>
      <img
        className={styles.iconError}
        src='./images/404.svg'
        alt='404-not-found'
      />
    </div>
  );
};

export default NotFound;
