import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUrlWithParams, urls } from 'store/api';
import { apiClient } from 'utils/http';
import notify from 'components/Notifications';

import {
  IDialog,
  IDialogEditRequest,
  IDialogResponse,
  IImageType,
  IMessage,
  IMessageRequest,
  IResultMessage,
} from './interfaces';

export const getDialogs = createAsyncThunk<IDialog[], void>(
  'getDialogs',
  async () => {
    try {
      const { data } = await apiClient.get(urls.api.chat.get);
      return data;
    } catch (error) {
      notify({
        type: 'error',
        message: 'Ошибка при получении списка диалогов',
      });
      throw error;
    }
  }
);

export const createDialog = createAsyncThunk<IDialogResponse, { name: string }>(
  'createDialog',
  async ({ name }) => {
    try {
      const { data } = await apiClient.post(urls.api.chat.post, { name });
      return data;
    } catch (error) {
      notify({
        type: 'error',
        message: 'Ошибка при cоздании диалога',
      });
      throw error;
    }
  }
);

export const editChat = createAsyncThunk<
  IDialogEditRequest,
  { id: string; name?: string; is_active?: boolean }
>('editChat', async ({ id, name, is_active }) => {
  const { data } = await apiClient.patch(
    getUrlWithParams(urls.api.chat.patch, { id }),
    { name, is_active }
  );
  return data;
});

export const getMessages = createAsyncThunk<
  IMessage,
  { id: string; nextUrl?: string }
>('getMessages', async ({ id, nextUrl }) => {
  const params = new URLSearchParams();
  params.set('chat_id', id);
  params.set('page_size', '10');
  if (nextUrl) {
    const { data } = await apiClient.get(nextUrl);
    return data;
  } else {
    const { data } = await apiClient.get(
      `${urls.api.message.get}?${params.toString()}`
    );
    return data;
  }
});

export const getNextMessages = createAsyncThunk<
  IMessage,
  { id: string; nextUrl?: string }
>('getNextMessages', async ({ id, nextUrl }) => {
  const params = new URLSearchParams();
  params.set('chat_id', id);
  params.set('page_size', '10');

  const { data } = await apiClient.get(nextUrl);

  return data;
});

export const createMessage = createAsyncThunk<
  IResultMessage[],
  IMessageRequest
>('createMessage', async ({ body, chat_id }) => {
  try {
    const { data } = await apiClient.post(urls.api.message.post, {
      body,
      chat_id,
    });
    return [data, { body, chat_id, agent: 'user' }];
  } catch (error) {
    if (error.response?.status === 403) {
      notify({
        type: 'warning',
        message: `${Object.values(error.response.data)[0]}`,
      });
      throw error;
    }
    if (error.response?.status === 500) {
      notify({
        type: 'error',
        message:
          'Сервис временно недоступен, попробуйте повторить попытку позже',
      });
      throw error;
    }
    if (error.response?.status === 504) {
      notify({
        type: 'error',
        message:
          'Превышено время ожидания, обновите страницу и/или попробуйте позже',
      });
      throw error;
    }

    notify({
      type: 'error',
      message: 'Ошибка при отправке сообщения',
    });
    throw error;
  }
});

export const getModelTypes = createAsyncThunk<IImageType[], void>(
  'getModelTypes',
  async () => {
    const { data } = await apiClient.get(urls.api.imageType.get);
    return data;
  }
);

export const generateImage = createAsyncThunk<any, any>(
  'generateImage',
  async ({ promt, chat_id, slug }) => {
    try {
      const { data } = await apiClient.post(urls.api.image.post, {
        promt,
        chat_id,
        slug,
      });
      return [{ body: promt, chat_id, agent: 'user', id: data.id }];
    } catch (error) {
      if (error.response?.status === 403) {
        notify({
          type: 'warning',
          message: `${Object.values(error.response.data)[0]}`,
        });
        throw error;
      }
      if (error.response?.status === 500) {
        notify({
          type: 'error',
          message:
            'Сервис временно недоступен, попробуйте повторить попытку позже',
        });
        throw error;
      }
      if (error.response?.status === 504) {
        notify({
          type: 'error',
          message:
            'Превышено время ожидания, обновите страницу и/или попробуйте позже',
        });
        throw error;
      }

      notify({
        type: 'error',
        message: 'Ошибка при генерации изображения',
      });
      throw error;
    }
  }
);

export const getDoneImage = createAsyncThunk<
  any[],
  { id: string; body: string; chat_id: string }
>('getDoneImage', async ({ id, chat_id }) => {
  const { data } = await apiClient.get(
    getUrlWithParams(urls.api.image.get, {
      id,
    })
  );
  if (data?.url) {
    return [{ body: data.url, chat_id, agent: 'assistant', type: 'image' }];
  }
  return data;
});
