import { useAppSelector } from 'store/store';

import styles from './UserStatistics.module.scss';

interface ISubscription {
  type: string;
  started_at: string;
  expired_at: string;
}

const compareSubscriptions = (a, b) => {
  if (new Date(a.expired_at) < new Date(b.expired_at)) {
    return -1;
  } else if (new Date(a.expired_at) > new Date(b.expired_at)) {
    return 1;
  } else {
    return 0;
  }
};

const sortSubscriptions = (subscriptions: ISubscription[]): ISubscription[] => {
  const fullSubscriptions = subscriptions.filter(
    subscription => subscription.type === 'full'
  );
  const minimalSubscriptions = subscriptions.filter(
    subscription => subscription.type === 'minimal'
  );

  fullSubscriptions.sort(compareSubscriptions);
  minimalSubscriptions.sort(compareSubscriptions);

  const sortedSubscriptions = [...fullSubscriptions, ...minimalSubscriptions];

  return sortedSubscriptions;
};

interface ISubscriptionProps {
  subscription: ISubscription;
}

const Subscription: React.FC<ISubscriptionProps> = ({ subscription }) => {
  const isSubscriptionActive = new Date(subscription.expired_at) > new Date();

  if (!isSubscriptionActive) {
    return null;
  }

  return (
    <>
      <div className={styles.statisticsString}>
        <p className={styles.statisticsText}>подписка</p>
        <p className={styles.statisticsValue}>{subscription.type}</p>
      </div>
      <div className={styles.statisticsString}>
        <p className={styles.statisticsText}>активна</p>
        <p className={styles.statisticsValue}>
          {`до ${new Date(subscription.expired_at).toLocaleDateString(
            'ru-RU'
          )}`}
        </p>
      </div>
    </>
  );
};
const UserStatistics: React.FC = () => {
  const { remaining_requests, total_messages, active_days, subscription } =
    useAppSelector(state => state.user);

  const sortedSubscriptions = sortSubscriptions(subscription);

  return (
    <div className={styles.statistics}>
      <div className={styles.statisticsString}>
        <p className={styles.statisticsText}>оставшиеся запросы</p>
        <p className={styles.statisticsValue}>
          {remaining_requests === -1 ? `∞` : remaining_requests}
        </p>
      </div>

      {sortedSubscriptions.map((subscription, index) => (
        <Subscription key={index} subscription={subscription} />
      ))}
      {sortedSubscriptions.length === 0 && (
        <div className={styles.statisticsString}>
          <p className={styles.statisticsText}>подписка</p>
          <p className={styles.statisticsValue}>–</p>
        </div>
      )}

      <div className={styles.statisticsString}>
        <p className={styles.statisticsText}>сообщений в чате</p>
        <p className={styles.statisticsValue}>{total_messages}</p>
      </div>
      <div className={styles.statisticsString}>
        <p className={styles.statisticsText}>дней с нами</p>
        <p className={styles.statisticsValue}>{active_days || 0}</p>
      </div>
    </div>
  );
};

export default UserStatistics;
