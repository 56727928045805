import Distinction from 'components/Distinction';
import EdyaFunctions from 'components/EdyaFunctions';
import FAQ from 'components/FAQ';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Line from 'components/Line';
import MainBanner from 'components/MainBanner';
import MetrikaComponent from 'components/MetrikaComponent';
import ParticlesWrap from 'components/ParticlesWrap/ParticlesWrap';
import { SeoMetaTitles } from 'components/SeoMetaTitles';
import ScrollToHashElement from 'utils/ScrollToHashElement.jsx';

const MainPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <MetrikaComponent />
      <ParticlesWrap />
      <Layout>
        <Header />
        <MainBanner />
        <EdyaFunctions />
        <Distinction />
        <FAQ />
      </Layout>
      <Line />
      <Layout>
        <Footer />
      </Layout>
      <ScrollToHashElement />
    </>
  );
};

export default MainPage;
