import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ConfirmEmail from 'components/ConfirmEmail';
import Loader from 'components/Loaders/Loader';
import { TelegramWidget } from 'components/TelegramWidget';
import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import Recaptcha from 'components/Recaptcha';
import { omit } from 'utils/helpers';
import { registration } from 'utils/auth';
import { useAppSelector } from 'store/store';

import Button from '../Button/Button';
import {
  defaultValues,
  IRegistrationState,
  validationSchema,
} from './formUtils';
import styles from './Registration.module.scss';

const Registration: React.FC = () => {
  const { captchaToken } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenEmailIsSend, setIsOpenEmailIsSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = (values: IRegistrationState) => {
    const filteredFields = omit(values, ['repeatPassword']);

    setIsLoading(true);
    registration({ ...filteredFields, token: captchaToken })
      .then(() => {
        setIsLoading(false);
        setIsOpenEmailIsSend(true);
      })
      .catch(({ response }) => {
        setErrorMessage(response.data);
        setIsLoading(false);
      });
  };

  const errorMessageWrap = () => {
    return Object.keys(errorMessage).length ? (
      <p className={styles.errorMessage}>
        {errorMessage[Object.keys(errorMessage)[0]]}
      </p>
    ) : null;
  };

  const email = useWatch({
    control: methods.control,
    name: 'email',
  });

  return !isLoading ? (
    <div className={styles.registration}>
      <Recaptcha />
      {!isOpenEmailIsSend ? (
        <FormProvider {...methods}>
          <form
            className={styles.loginForm}
            onSubmit={methods.handleSubmit(submit)}
          >
            <ControlledInput name='firstName' placeholder='Введите ваше имя' />

            <ControlledInput name='email' placeholder='Введите ваш e-mail' />
            <ControlledInput
              name='password'
              placeholder='Введите пароль'
              type='password'
            />
            <ControlledInput
              name='repeatPassword'
              placeholder='Повторите пароль'
              type='password'
            />
            {errorMessageWrap()}
            <Button
              size='md'
              variant='autorization'
              textButton='authorizationText'
              type='submit'
            >
              зарегистрироваться
            </Button>

            <TelegramWidget />
          </form>
        </FormProvider>
      ) : null}

      {isOpenEmailIsSend ? <ConfirmEmail email={email} /> : null}
    </div>
  ) : (
    <Loader size='standard' />
  );
};

export default Registration;
