import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/store';
import { isAuthenticated } from 'utils/auth';
import { getAccessToken } from 'utils/token';
import { getUser } from 'store/services/user/actions';
import { clearUser } from 'store/services/user/user.slice';
import LoaderDots from 'components/Loaders/LoaderDots';

import Button from '../Button';
import { useBreakpoints, BreakpointsEnum } from '../../hooks/useBreakpoints';
import styles from './Header.module.scss';

const Header: React.FC = () => {
  const [isBurgerShown, setIsBurgerShown] = useState(false);
  const [isUserMenuShow, setIsUserMenuShow] = useState(false);

  const dispatch = useAppDispatch();
  const { last_name, first_name, avatar_url, isUserInfoLoading } =
    useAppSelector(state => state.user);

  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      !first_name && dispatch(getUser());
    }
  }, []);

  const getUserName = () => {
    if (first_name && last_name) {
      const firstLetterLastName = last_name.charAt(0).toUpperCase();
      return `${first_name} ${firstLetterLastName}.`;
    } else if (first_name && !last_name) {
      return first_name;
    } else {
      return 'Имя Фамилия';
    }
  };

  const handleClick = () => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.toggle(styles.bodyNavigationOpen);
    }
    setIsBurgerShown(!isBurgerShown);
  };

  const closeMobileMenu = () => {
    const body = document.querySelector('body');
    if (body && body?.classList.contains(styles.bodyNavigationOpen)) {
      body.classList.remove(styles.bodyNavigationOpen);
    }
    setIsBurgerShown(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const isSmallScreen =
    useBreakpoints() == BreakpointsEnum.mobile || BreakpointsEnum.mobile;

  const logoutButton = () => {
    closeMobileMenu();
    dispatch(clearUser());
    localStorage.clear();
  };

  const navigationListStyles =
    (isBurgerShown
      ? `${styles.navigationList} ${styles.navigationOpen}`
      : styles.navigationList,
    isUserMenuShow
      ? `${styles.navigationList} ${styles.navigationOpen} ${styles.userMenuOpen}`
      : `${styles.navigationList} ${styles.navigationOpen}`);

  return (
    <div className={styles.headerMain}>
      <div className={styles.header}>
        <NavLink to='/'>
          <img
            className={styles.logo}
            src='/images/logoMain.png'
            alt='logo-main'
          />
        </NavLink>

        <div
          className={
            isBurgerShown
              ? `${styles.burger} ${styles.navigationOpen}`
              : styles.burger
          }
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav
          className={
            isBurgerShown
              ? `${styles.navigation} ${styles.navigationOpen}`
              : styles.navigation
          }
        >
          <ul className={navigationListStyles}>
            <NavLink
              to='/#faq'
              onClick={closeMobileMenu}
              className={styles.navigationLink}
            >
              Как это работает?
            </NavLink>
            <NavLink
              to='/about'
              onClick={closeMobileMenu}
              className={styles.navigationLink}
            >
              О нас
            </NavLink>
            <NavLink
              to='/prices'
              onClick={closeMobileMenu}
              className={styles.navigationLink}
            >
              Цена
            </NavLink>
            <NavLink
              to='/comming-soon'
              onClick={closeMobileMenu}
              className={styles.navigationLink}
            >
              API
            </NavLink>
          </ul>

          {!isAuthenticated() ? (
            <div
              className={
                isBurgerShown
                  ? `${styles.buttonWrap} ${styles.navigationOpen}`
                  : styles.buttonWrap
              }
            >
              <NavLink to='/authorization' onClick={closeMobileMenu}>
                <Button size='sm' variant='default' textButton='headerText'>
                  авторизация
                </Button>
              </NavLink>

              <NavLink to='/registration' onClick={closeMobileMenu}>
                <Button size='sm' variant='default' textButton='headerText'>
                  регистрация
                </Button>
              </NavLink>
            </div>
          ) : isUserInfoLoading ? (
            <div className={styles.loaderWrap}>
              <LoaderDots type='default' />
            </div>
          ) : (
            <div
              className={
                isBurgerShown
                  ? `${styles.userInfo} ${styles.navigationOpen}`
                  : styles.userInfo
              }
              onMouseEnter={() =>
                isSmallScreen ? null : setIsUserMenuShow(true)
              }
              onMouseLeave={() =>
                isSmallScreen ? null : setIsUserMenuShow(false)
              }
              onClick={() =>
                isSmallScreen ? setIsUserMenuShow(!isUserMenuShow) : null
              }
            >
              <p className={styles.userName}>{getUserName()}</p>
              <img
                className={classNames(styles.avatarImg, {
                  [styles.avatarUser]: avatar_url,
                })}
                src={avatar_url ? avatar_url : './images/icons/userProfile.png'}
                alt='avatar-img'
              />

              {isUserMenuShow ? (
                <>
                  <div
                    className={
                      isBurgerShown
                        ? `${styles.userNavigation} ${styles.userNavigationTablet}`
                        : styles.userNavigation
                    }
                  >
                    <NavLink
                      to='/dialog'
                      onClick={closeMobileMenu}
                      className={styles.userNavigationText}
                    >
                      диалог
                    </NavLink>
                    <NavLink
                      to='/profile'
                      onClick={closeMobileMenu}
                      className={styles.userNavigationText}
                    >
                      профиль
                    </NavLink>
                    <NavLink
                      to='/#faq'
                      onClick={closeMobileMenu}
                      className={styles.userNavigationText}
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      to='/'
                      onClick={logoutButton}
                      className={styles.userNavigationText}
                    >
                      выход
                    </NavLink>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Header;
