import { useState, useEffect, useRef } from 'react';

type AnyFunction = (...args: any[]) => any;

interface DebouncedFunction<F extends AnyFunction> {
  (this: ThisParameterType<F>, ...args: Parameters<F>): void;
  cancel: () => void;
}

function useDebounce<F extends AnyFunction>(
  func: F,
  wait: number
): DebouncedFunction<F> {
  const [timerId, setTimerId] = useState<number | undefined>(undefined);
  const latestFunc = useRef(func);

  useEffect(() => {
    latestFunc.current = func;
  }, [func]);

  function debouncedFunction(
    this: ThisParameterType<F>,
    ...args: Parameters<F>
  ) {
    if (timerId) {
      window.clearTimeout(timerId);
    }

    const newTimerId = window.setTimeout(() => {
      latestFunc.current.apply(this, args);
      setTimerId(undefined);
    }, wait);

    setTimerId(newTimerId);
  }

  debouncedFunction.cancel = () => {
    if (timerId) {
      window.clearTimeout(timerId);
      setTimerId(undefined);
    }
  };

  return debouncedFunction;
}

export default useDebounce;
