import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import Button from 'components/Button';
import Layout from 'components/Layout';
import NotFoundPage from 'pages/NotFoundPage';

import styles from './ErrorBoundary.module.scss';

const ErrorBoundary = () => {
  const error = useRouteError();

  const handleReload = () => {
    window.location.reload();
  };

  console.error(error);

  if (isRouteErrorResponse(error)) {
    if (error?.status === 404) {
      return <NotFoundPage />;
    }
  }

  return (
    <Layout>
      <div className={styles.errorCountainer}>
        <h1 className={styles.errorTitle}>Упс!</h1>
        <h2 className={styles.errorSubtitle}>Произошла ошибка</h2>
        <p className={styles.errorText}>
          Что-то пошло не так. Пожалуйста, перезагрузите страницу или попробуйте
          позже.
        </p>
        <div className={styles.errorButton}>
          <Button
            onClick={handleReload}
            size='lg'
            variant='default'
            textButton='defaultText'
          >
            обновить страницу
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default ErrorBoundary;
