import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import Loader from 'components/Loaders/Loader';
import { TelegramWidget } from 'components/TelegramWidget';
import Recaptcha from 'components/Recaptcha';
import { omit } from 'utils/helpers';
import { login } from 'utils/auth';
import { useAppSelector } from 'store/store';

import { defaultValues, ILoginState, validationSchema } from './formUtils';
import Button from '../Button/Button';
import styles from './Authorization.module.scss';

const Authorization: React.FC = () => {
  const navigate = useNavigate();
  const { captchaToken } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = (values: ILoginState) => {
    const filteredFields = omit(values, ['remember']);

    setIsLoading(true);
    login({ ...filteredFields, token: captchaToken })
      .then(() => {
        setIsLoading(false);
        navigate('/profile');
      })
      .catch(error => {
        error.error === 'missing_captcha_token'
          ? setErrorMessage(
              'подозрительные действия на сайте, возможно вы бот. пожалуйста, попробуйте еще раз'
            )
          : setErrorMessage(error.error_description);
        setIsLoading(false);
      });
  };

  const errorMessageWrap = () => {
    const firstErrorMessage = Object.values(errorMessage);
    if (firstErrorMessage !== null && firstErrorMessage !== undefined)
      return firstErrorMessage ? (
        <p className={styles.errorMessage}>{firstErrorMessage}</p>
      ) : null;
  };

  return !isLoading ? (
    <div className={styles.authorization}>
      <Recaptcha />
      <FormProvider {...methods}>
        <form
          className={styles.loginForm}
          onSubmit={methods.handleSubmit(submit)}
        >
          <div>
            <ControlledInput placeholder='имя пользователя' name='username' />
            <ControlledInput
              placeholder='пароль'
              type='password'
              name='password'
            />
            <div className={styles.remember}>
              <Link to={'/forgot-password'} className={styles.rememberLink}>
                забыли пароль?
              </Link>
            </div>
          </div>
          {errorMessageWrap()}
          <Button
            size='md'
            variant='autorization'
            textButton='authorizationText'
            type='submit'
          >
            войти
          </Button>

          <TelegramWidget />
        </form>
      </FormProvider>
    </div>
  ) : (
    <Loader size='standard' />
  );
};

export default Authorization;
