import styles from './ConfirmEmail.module.scss';

interface IConfirmEmailProps {
  email: string;
}

const ConfirmEmail: React.FC<IConfirmEmailProps> = ({ email }) => {
  return (
    <div className={styles.confirmEmail}>
      <h2 className={styles.title}>Завершите регистрацию</h2>
      <div className={styles.info}>
        <div className={styles.text}>
          <div className={styles.text__accent}>Подтвердите email</div>
          Мы отправили ссылку на <span>{email}</span>
          <p>Если вы не видите письмо, проверьте папку «Спам»</p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
