import { Fragment } from 'react';

import styles from './InitialElement.module.scss';

const InitialElement: React.FC = () => {
  return (
    <Fragment>
      <div className={styles.logoWrap}>
        <img
          className={styles.logoEdya}
          src='./images/logoMain.png'
          alt='logo-edya'
        />
      </div>
      <div className={styles.declarationWrap}>
        <div className={styles.examplesWrap}>
          <div className={styles.titleWrap}>
            <img
              className={styles.iconDeclaration}
              src='./images/icons/lamp.png'
              alt='lamp-icon'
            />
            <p className={styles.title}>Примеры</p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Объясни теорему Пифагора простыми словами
            </p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Придумай стартап для бизнеса с малым бюджетом
            </p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Напиши код калькулятора на языке Python
            </p>
          </div>
        </div>

        <div className={styles.examplesWrap}>
          <div className={styles.titleWrap}>
            <img
              className={styles.iconDeclaration}
              src='./images/icons/cog.png'
              alt='cog-icon'
            />
            <p className={styles.title}>Возможности</p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Запоминает контекст диалога
            </p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>Обучается на ошибках</p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Обучен отклонять неуместные запросы
            </p>
          </div>
        </div>

        <div className={styles.examplesWrap}>
          <div className={styles.titleWrap}>
            <img
              className={styles.iconDeclaration}
              src='./images/icons/warning.png'
              alt='warning-icon'
            />
            <p className={styles.title}>Ограничения</p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Иногда может генерировать неверную информацию
            </p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Иногда может содержать вредные инструкции или предвзятое
              содержание
            </p>
          </div>
          <div className={styles.example}>
            <p className={styles.textDeclaration}>
              Ограниченные знания о мире и событиях после 2021 года
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InitialElement;
