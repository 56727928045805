import Yup from 'utils/yup';

export interface IRegistrationState {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const defaultValues: IRegistrationState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  repeatPassword: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный email')
    .trim()
    .required('Введите email'),
  password: Yup.string()
    .trim()
    .required('Введите пароль')
    .min(8)
    .matches(
      /^([!@#$%^&*()\-_=+{};:,<.>a-zA-Z0-9])*$/,
      'Пароль может содержать только латинские буквы, цифры и спецсимволы(!@#$%^&*()-_=+{};:,<.>)'
    ),
  repeatPassword: Yup.string()
    .test('isMatch', 'Пароли должны совпадать', function (value) {
      return value === this.parent.password;
    })
    .required('Введите пароль ещё раз'),
  firstName: Yup.string()
    .required('Введите имя')
    .min(2, 'Минимум 2 символа')
    .max(150, 'Максимум 150 символов'),
  lastName: Yup.string().max(150, 'Максимум 150 символов'),
});
