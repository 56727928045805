import { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement = () => {
  let location = useLocation();
  const [hashElement, setHashElement] = useState(null);

  useLayoutEffect(() => {
    let hash = location.hash;
    const removeHashCharacter = str => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      setHashElement(element);
    } else {
      setHashElement(null);
    }
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: 'smooth',
        // block: 'end',
        inline: 'nearest',
      });
    }
  }, [hashElement, location]);

  return null;
};

export default ScrollToHashElement;
