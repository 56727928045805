import classNames from 'classnames';

import styles from './Loader.module.scss';

type TProps = {
  size: 'standard' | 'small';
};
const Loader: React.FC<TProps> = ({ size }) => {
  return (
    <div className={classNames(styles[size], styles.loaderWrap)}>
      <div className={classNames(styles.loader, styles.loaderItem)}>
        <div>
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
