import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from 'store/store';
import {
  editUser,
  getUser,
  getSubscription,
} from 'store/services/user/actions';
import Loader from 'components/Loaders/Loader';
import notify from 'components/Notifications';

import Button from '../Button';
import { telegramValidationSchema, validationSchema } from './formUtils';
import { IEditFormValues } from './Profile.types';
import { ProfileFields } from './ProfileFields/ProfileFields';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import styles from './Profile.module.scss';

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUser());
    dispatch(getSubscription());
  }, []);

  const { last_name, first_name, email, isUserInfoLoading, is_telegram_user } =
    useAppSelector(state => state.user);
  const hasOnlyTelegram = is_telegram_user && !email;

  const methods = useForm({
    mode: 'onChange',
    resolver: hasOnlyTelegram
      ? yupResolver(telegramValidationSchema)
      : yupResolver(validationSchema),
  });

  useEffect(() => {
    methods.reset({
      firstName: first_name,
      lastName: last_name,
      email: email,
      oldPassword: '',
      password: '',
      repeatPassword: '',
    });
  }, [first_name]);

  const submit = (values: IEditFormValues) => {
    let formattedValues = null;

    if (hasOnlyTelegram && values.email) {
      const passwordValues =
        values.password && values.repeatPassword
          ? { password: values.password }
          : {};

      formattedValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        ...passwordValues,
      };
    } else if (hasOnlyTelegram && !values.email) {
      formattedValues = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
    } else {
      const passwordValues =
        values.password && values.oldPassword
          ? { old_password: values.oldPassword, password: values.password }
          : {};

      formattedValues = {
        first_name: values.firstName,
        last_name: values.lastName,
        ...passwordValues,
      };
    }

    dispatch(editUser(formattedValues)).then(res => {
      if ((res as any)?.error) {
        const errorValues = Object.values(res.payload);

        const message = errorValues.length
          ? `${errorValues[0][0]}` || 'Произошла ошибка, повторите попытку'
          : 'Ошибка при редактировании пользователя';
        return notify({
          type: 'error',
          message: message,
        });
      }
      if (hasOnlyTelegram && values.email) {
        localStorage.clear();
        navigate('/authorization');

        return setTimeout(
          () =>
            notify({
              type: 'success',
              message: 'Email успешно привязан, перейдите по ссылке в письме',
            }),
          1
        );
      }
      return notify({
        type: 'success',
        message: 'Данные успешно сохранены',
      });
    });

    methods.reset({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      oldPassword: '',
      password: '',
      repeatPassword: '',
    });
  };

  return (
    <div className={styles.profileContainer}>
      <Link to='/'>
        <Button size='xl' variant='profileExit' textButton='profileExitText'>
          <FontAwesomeIcon icon={faLeftLong} /> вернуться на сайт
        </Button>
      </Link>
      <div className={styles.profile}>
        {isUserInfoLoading ? (
          <Loader size='standard' />
        ) : (
          <div className={styles.userInfo}>
            <ProfileInfo />
            <div className={styles.basicInfoWrap}>
              <FormProvider {...methods}>
                <form
                  className={styles.basicInfo}
                  onSubmit={methods.handleSubmit(submit)}
                >
                  <ProfileFields />
                </form>
              </FormProvider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
