import { createSlice } from '@reduxjs/toolkit';

import {
  createMessage,
  generateImage,
  getDialogs,
  getDoneImage,
  getMessages,
  getModelTypes,
  getNextMessages,
} from './actions';
import { IDialogsState } from './interfaces';

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    dialogs: [],
    messages: {},
    isLoading: false,
    isMessageLoading: false,
    nextUrl: '',
    modelTypes: [],
    mode: 'text',
    slug: null,
  } as IDialogsState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setSlug: (state, action) => {
      state.slug = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getDialogs.fulfilled, (state, { payload }) => {
      state.isDialogsLoading = false;
      state.dialogs = [...payload];
    });
    builder.addCase(getDialogs.pending, state => {
      state.isDialogsLoading = true;
    });
    builder.addCase(getDialogs.rejected, state => {
      state.isDialogsLoading = false;
    });
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.nextUrl = payload.next;
      state.isMessageLoading = false;
      state.messages[payload.results?.[0]?.chat_id] = payload.results;
    });
    builder.addCase(getNextMessages.fulfilled, (state, { payload }) => {
      state.nextUrl = payload.next;
      state.isMessageLoading = false;

      if (payload.results.length) {
        state.messages[payload.results[0].chat_id] = [
          ...state.messages[payload.results[0].chat_id],
          ...payload.results,
        ];
      }
    });
    builder.addCase(getMessages.pending, state => {
      state.isMessageLoading = true;
    });
    builder.addCase(getMessages.rejected, state => {
      state.isMessageLoading = false;
    });
    builder.addCase(createMessage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createMessage.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createMessage.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (!state.messages[payload[0].chat_id]) {
        state.messages[payload[0].chat_id] = [...payload];
      } else {
        state.messages[payload[0].chat_id] = [
          ...payload,
          ...state.messages[payload[0].chat_id],
        ];
      }
    });
    builder.addCase(getModelTypes.fulfilled, (state, { payload }) => {
      state.modelTypes = payload;
    });
    builder.addCase(generateImage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generateImage.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(generateImage.fulfilled, (state, { payload }) => {
      if (!state.messages[payload[0].chat_id]) {
        state.messages[payload[0].chat_id] = [...payload];
      } else {
        state.messages[payload[0].chat_id] = [
          ...payload,
          ...state.messages[payload[0].chat_id],
        ];
      }
    });
    builder.addCase(getDoneImage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getDoneImage.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(getDoneImage.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.isLoading = false;

        if (!state.messages[payload[0].chat_id]) {
          state.messages[payload[0].chat_id] = [...payload];
        } else {
          state.messages[payload[0].chat_id] = [
            ...payload,
            ...state.messages[payload[0].chat_id],
          ];
        }
      }
    });
  },
});

export const { setMode, setSlug } = dialogSlice.actions;
