import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faComments } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from 'store/store';
import { IImageType } from 'store/services/dialogs/interfaces';
import { setMode, setSlug } from 'store/services/dialogs/dialogs.slice';

import styles from './GenerationTypeSelection.module.scss';

const GenerationTypeSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { modelTypes } = useAppSelector(state => state.dialog);

  const [isNeuronetShown, setIsNeuronetShown] = useState(false);
  const [isTextGeneratorShown, setIsTextGeneratorShown] = useState(false);

  const handleNeuronetShown = () => {
    setIsNeuronetShown(!isNeuronetShown);
  };

  const choisedTextGenerator = () => {
    setIsTextGeneratorShown(false);

    dispatch(setMode('text'));
  };

  const choisedNeuronet = (slug: string) => {
    setIsNeuronetShown(false);
    setIsTextGeneratorShown(true);

    dispatch(setMode('image'));
    dispatch(setSlug(slug));
  };

  const neuronetComponent = ({ slug, label }: IImageType) => {
    return (
      <div onClick={() => choisedNeuronet(slug)} className={styles.neuronet}>
        {label}
      </div>
    );
  };

  return (
    <div className={styles.generationTypeSelection}>
      {!isTextGeneratorShown ? (
        <div
          onClick={handleNeuronetShown}
          className={classNames(styles.buttonGeneratorWrap, {
            [styles.imageGenerator]: isNeuronetShown === false,
          })}
        >
          <div className={styles.neuronetBurgers}>
            {isNeuronetShown
              ? modelTypes.map(({ slug, label }) => (
                  <Fragment key={slug}>
                    {neuronetComponent({ slug, label })}
                  </Fragment>
                ))
              : null}
          </div>

          <FontAwesomeIcon icon={faCamera} className={styles.buttonGenerator} />
        </div>
      ) : (
        <div
          onClick={choisedTextGenerator}
          className={classNames(
            styles.buttonGeneratorWrap,
            styles.textGenerator
          )}
        >
          <FontAwesomeIcon
            icon={faComments}
            className={styles.buttonGenerator}
          />
        </div>
      )}
    </div>
  );
};

export default GenerationTypeSelection;
