import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import LoaderDots from 'components/Loaders/LoaderDots';
import notify from 'components/Notifications';
import { ControlledGroupRadio } from 'components/ControlledGroupRadio';
import { sendSurvay } from 'utils/auth';
import { useAppSelector } from 'store/store';

import {
  ICommingState,
  PurposeEnum,
  defaultValues,
  validationSchema,
} from './formUtils';
import Button from '../Button/Button';
import styles from './CommingSoon.module.scss';

const CommingSoon: React.FC = () => {
  const { captchaToken } = useAppSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = (values: ICommingState) => {
    setIsLoading(true);
    sendSurvay({ ...values, token: captchaToken })
      .then(() => {
        setIsLoading(false);
        notify({
          type: 'success',
          message: 'Ваш запрос отправлен! ',
        });
        methods.reset();
      })
      .catch(error => {
        setErrorMessage(error?.detail);
        setIsLoading(false);
      });
  };

  const errorMessageWrap = () => {
    if (errorMessage) {
      const firstErrorMessage = Object.values(errorMessage);
      if (
        errorMessage &&
        firstErrorMessage !== null &&
        firstErrorMessage !== undefined
      )
        return firstErrorMessage ? (
          <p className={styles.errorMessage}>{firstErrorMessage}</p>
        ) : null;
    }
  };

  return (
    <div className={styles.authorization}>
      <p className={styles.aboutTitle}> Comming Soon...</p>
      <div className={styles.aboutWrap}>
        <p>
          Планируемая дата выпуска общедоступного API для Midjourney — лето 2023
        </p>
        <p>
          Если Вы хотите получить доступ к ранней версии API — заполните форму
          ниже
        </p>
      </div>

      <FormProvider {...methods}>
        <form
          className={styles.loginForm}
          onSubmit={methods.handleSubmit(submit)}
        >
          <ControlledInput placeholder='ваше имя' name='name' />
          <ControlledInput
            placeholder='название компании'
            name='company_name'
          />
          <ControlledInput
            placeholder='чем занимается ваша компания?'
            name='company_description'
          />
          <ControlledInput placeholder='введите ваш email' name='email' />
          <div>
            <p className={styles.question}>
              Для какой цели вы хотите использовать API?
            </p>
            <ControlledGroupRadio
              name='purpose_of_use'
              options={[
                { value: PurposeEnum.integration, label: 'Внедрение в проект' },
                {
                  value: PurposeEnum.personalUse,
                  label: 'Для личного пользования',
                },
                {
                  value: PurposeEnum.supporting,
                  label: 'Хочу помочь команде Edya в тестировании',
                },
              ]}
            />
          </div>
          {errorMessageWrap()}
          <Button
            size='md'
            variant='autorization'
            textButton='authorizationText'
            type='submit'
          >
            {isLoading ? (
              <LoaderDots type='form' loaderDot='formDots' />
            ) : (
              'отправить'
            )}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default CommingSoon;
