import { combineReducers } from 'redux';

import { userSlice } from './services/user/user.slice';
import { dialogSlice } from './services/dialogs/dialogs.slice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  dialog: dialogSlice.reducer,
});

const reducer: typeof rootReducer = (state: any, action: any) => {
  return rootReducer(state, action);
};

export default reducer;
