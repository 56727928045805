import classNames from 'classnames';

import styles from './LoaderDots.module.scss';

type TProps = {
  type?: 'dialogs' | 'messages' | 'default' | 'form';
  loaderDot?: 'defaultDots' | 'formDots';
};

const LoaderDots: React.FC<TProps> = ({
  type = 'default',
  loaderDot = 'defaultDots',
}) => {
  return (
    <div className={classNames(styles[type], styles.loaderDots)}>
      <div className={styles[loaderDot]}></div>
      <div className={styles[loaderDot]}></div>
      <div className={styles[loaderDot]}></div>
    </div>
  );
};

export default LoaderDots;
