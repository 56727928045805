import { Link } from 'react-router-dom';

import Button from 'components/Button';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { useAppSelector } from 'store/store';
import { BreakpointsEnum, useBreakpoints } from 'hooks/useBreakpoints';

import UserStatistics from '../UserStatistics';
import styles from './ProfileInfo.module.scss';

export const ProfileInfo = () => {
  const { last_name, first_name, subscription } = useAppSelector(
    ({ user }) => user
  );

  const isMobile = useBreakpoints() === BreakpointsEnum.mobile;
  const firstLetterFirstName = `${first_name.charAt(0).toUpperCase()}.`;
  const isPremium =
    subscription?.length && new Date(subscription[0].expired_at) > new Date();

  const getMobileUserName = () => {
    return last_name ? (
      <p className={styles.userName}>{`${firstLetterFirstName || 'Имя'} ${
        last_name || 'Фамилия'
      }`}</p>
    ) : (
      <p className={styles.userName}>{first_name || 'Имя'} </p>
    );
  };

  const getDesktopUserName = () => {
    return last_name ? (
      <p className={styles.userName}>{`${first_name || 'Имя'} ${
        last_name || 'Фамилия'
      }`}</p>
    ) : (
      <p className={styles.userName}>{first_name || 'Имя'} </p>
    );
  };

  return (
    <>
      <div className={styles.avatarBlockWrap}>
        <div className={styles.avatarBlock}>
          <ImageUploader />
          <div className={styles.infoWrap}>
            {isMobile ? getMobileUserName() : getDesktopUserName()}
            {isPremium ? (
              <div className={styles.premiumWrap}>
                <p className={styles.premiumText}>premium</p>
                <img
                  className={styles.premiumImg}
                  src='./images/icons/start.png'
                />
              </div>
            ) : null}
          </div>
        </div>

        {!isPremium ? (
          <Link to='/prices'>
            <Button size='xl' variant='default' textButton='premiumProfileText'>
              оформить PREMIUM
              <img
                className={styles.premiumImg}
                src='./images/icons/start.png'
              />
            </Button>
          </Link>
        ) : null}

        <Link to='/dialog'>
          <Button size='xl' variant='default' textButton='premiumProfileText'>
            перейти в диалог
            <img
              className={styles.edyaImg}
              src='./images/icons/logoEdyaWhite.png'
            />
          </Button>
        </Link>

        <UserStatistics />
      </div>
    </>
  );
};
