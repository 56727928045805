import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeHighlight from 'rehype-highlight';
import classNames from 'classnames';

import { useAppSelector } from 'store/store';

import styles from './Message.module.scss';

export const Message = ({ message }) => {
  const { avatar_url } = useAppSelector(state => state.user);

  const codeMatch = message.body.match(/\n\n```([\s\S]*?)```\n\n/);
  const codeText = codeMatch
    ? codeMatch[1].replace(/^\n/, '').replace(/^[\s\S]+?\n/, '')
    : null;

  return (
    <div
      className={classNames(styles.messageBox, {
        [styles.backgroundUser]: message.agent === 'user',
        [styles.backgroundEdya]: message.agent === 'assistant',
      })}
    >
      {message.agent === 'assistant' ? (
        <img
          className={styles.iconDialogEdya}
          src='./images/icons/logoEdyaWhite.png'
          alt='icon-user'
        />
      ) : (
        <img
          className={styles.iconDialog}
          src={avatar_url ? avatar_url : './images/icons/userProfile.png'}
          alt='icon-user'
        />
      )}
      {message.agent === 'assistant' && message.type === 'image' ? (
        <a
          className={styles.messageText}
          href={message.body}
          target='_blank'
          rel='noreferrer'
        >
          <img
            className={styles.imageResponse}
            src={message.body}
            alt='сгенерированное изображение'
          />
        </a>
      ) : (
        <div className={styles.messageText}>
          {codeText && (
            <div className={styles.copyButtonWrapper}>
              <button className='copyButton' data-clipboard-text={codeText}>
                копировать код
              </button>
            </div>
          )}
          <ReactMarkdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[
              rehypeKatex,
              () => rehypeHighlight({ ignoreMissing: true }),
            ]}
          >
            {message.body.replace(/\n/g, '  \n')}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
};
