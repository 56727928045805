import Zoom from 'react-medium-image-zoom';

import styles from './EdyaFunctions.module.scss';
import './mediumZoomStyles.scss';

const EdyaFunctions: React.FC = () => {
  return (
    <div className={styles.edyaFunctions}>
      <div className={styles.headerFunctions}>
        <div className={styles.headerFuncWrap}>
          <img
            className={styles.funcLogo}
            src='/images/icons/logoEdyaWhite.png'
            alt='logo'
          />
          <h1 className={styles.funcTitle}>Что делает Edya?</h1>
        </div>
        <p className={styles.funcSubtitle}>
          Edya GPT AI - твой интеллектуальный помощник и надежный друг! Получай
          удовольствие от мгновенных ответов на свои вопросы, реалистичной
          генерации изображений и многофункциональных возможностей для повышения
          эффективности. Открой новый уровень продуктивности, творчества и
          удобства с Edya GPT AI - твоим незаменимым помощником в цифровом мире!
        </p>
      </div>

      <div className={styles.leftBlock}>
        <div className={styles.leftBlockText}>
          <p className={styles.leftTitle}>
            Может писать тексты любой сложности
          </p>
          <p className={styles.leftSubtitle}>
            Сочинять стихи, писать пересказ заданного текста или страничку
            реферата. Напишет посты для социальных сетей или сценарий для видео
          </p>
        </div>
        <Zoom zoomMargin={40}>
          <img
            className={styles.blockImg}
            src='/images/code_1.png'
            alt='photo-function-1'
          />
        </Zoom>
      </div>

      <div className={styles.rightBlock}>
        <Zoom zoomMargin={40}>
          <img
            className={styles.blockImg}
            src='/images/code_2.png'
            alt='photo-function-1'
          />
        </Zoom>

        <div className={styles.rightBlockText}>
          <p className={styles.rightTitle}>
            Может работать <br />с кодом
          </p>
          <p className={styles.rightSubtitle}>
            Напишет или исправит код, найдет информацию, сделает рефакторинг и
            ещё много другое
          </p>
        </div>
      </div>

      <div className={styles.leftBlock}>
        <div className={styles.leftBlockText}>
          <p className={styles.leftTitle}>Может генерировать изображения</p>
          <p className={styles.leftSubtitle}>
            Сгенерирует изображение по вашему текстовому запросу лучшими
            нейросетями в интернете
          </p>
        </div>
        <Zoom zoomMargin={40}>
          <img
            className={styles.blockImg}
            src='/images/code_3.png'
            alt='photo-function-1'
          />
        </Zoom>
      </div>
    </div>
  );
};

export default EdyaFunctions;
