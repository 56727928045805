import { useEffect } from 'react';

import styles from './ContractOffer.module.scss';

const ContractOffer: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.contractOffer}>
      <p className={styles.contractTitle}>Договор-оферта</p>
      <p
        className={styles.contractWrap}
      >{`Настоящий договор-оферта (далее – "Договор") является публичной офертой "Edya" (далее – "Поставщик") и определяет условия использования сервиса Edya (далее – "Сервис") физическими и юридическими лицами (далее – "Пользователь").

1. Общие условия
1.1. Пользователь соглашается с условиями настоящего Договора путем использования Сервиса.
1.2. Настоящий Договор является публичной офертой, опубликованной на сайте Поставщика по адресу ai-edya.com (далее – "Сайт").
1.3. В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ), в случае принятия изложенных ниже условий и оплаты услуг юридическое или физическое лицо, производящее акцепт этой оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте).
1.4. Договор не требует скрепления печатями и/или подписания Поставщиком и Пользователем (далее по тексту - Стороны) и сохраняет при этом юридическую силу
1.5. Поставщик оставляет за собой право изменять условия настоящего Договора в любое время без предварительного уведомления Пользователя. Изменения вступают в силу с момента их опубликования на Сайте. 
1.6. Поставщик не несет ответственности за любой ущерб, причиненный Пользователю в связи с использованием Сервиса или невозможностью его использования.
1.7. Пользователь несет ответственность за сохранность своих учетных данных и обязуется не передавать их третьим лицам.
1.8. Поставщик не несет ответственности за сгенерированный текст и фотографии от нейросетей.
1.9. Поставщик не несет ответственности за любые последствия, возникшие в результате использования Сервиса Пользователем, в том числе, но не ограничиваясь, убытки, прямые или косвенные, упущенную выгоду, ущерб, связанный с прерыванием работы Сервиса.
      
2. Сбор и использование информации
2.1. Поставщик собирает информацию, предоставленную Пользователем при регистрации и использовании Сервиса, включая электронную почту, имя пользователя, персональную информацию, а также задаваемые запросы.
2.2. Информация, предоставленная Пользователем, используется Поставщиком только для обеспечения работы Сервиса и ведения аналитики. Задаваемые вопросы и присылаемые фотографии не просматриваются и остаются конфиденциальными.
2.3. Поставщик хранит информацию, предоставленную Пользователем, на своих серверах и принимает меры для защиты ее от несанкционированного доступа, использования или раскрытия. Информация также хранится в течение необходимого периода времени, чтобы обеспечить работу Сервиса.

3. Контакты
3.1. Если у Пользователя есть вопросы или комментарии по поводу настоящей политики конфиденциальности, он может связаться с Поставщиком через Telegram.

4. Подписка на использование сервиса
4.1. Поставщик предоставляет Пользователю подписку на использование Сервиса в соответствии с условиями настоящего Договора.
4.2. Подписка предоставляется на срок, который был указан при оплате и может быть отозвана Поставщиком в случае нарушения Пользователем условий настоящего Договора.

5. Оплата
5.1. Некоторые функции Сервиса могут быть доступны только после оплаты.
5.2. Стоимость услуги может быть изменена Исполнителем в одностороннем порядке. 
5.3. Стоимость услуг по Договору определяется в соответствии с действующими ценами и прописана на сайте.
5.4. Оплата производится Пользователем самостоятельно с помощью сервисов оплаты, доступных на Сайте.
5.5. Поставщик не несет ответственности за ошибки, допущенные при оплате Пользователем.

6. Условия возврата средств
6.1. Если Пользователь оплатил услугу, но не получил ее по какой-либо причине, он имеет право на возврат средств.
6.2. Запрос на возврат средств должен быть направлен на Telegram аккаунт Поставщика, указанного на Сайте. В запросе необходимо указать причину возврата.
6.3. Срок подачи запроса на возврат средств не должен превышать 7 дней с момента оплаты услуги.
6.4. Сумма возврата рассчитывается исходя из стоимости оплаченной услуги, за вычетом комиссии платежной системы.
6.5. Срок возврата средств составляет 7 дней с момента получения запроса на возврат.
6.6. Поставщик не несет ответственности за задержки в возврате денежных средств, вызванные действиями платежных систем.
6.7. Возврат средств не производится, если пользователь нарушил условия Договора или использовал услугу в нарушение законодательства.

7. Интеллектуальная собственность
7.1. Вся текстовая информация и графические изображения, находящиеся на сайте https://ai-edya.com, являются собственностью Поставщика.

8. Заключительные положения
8.1. Настоящий Договор может быть изменен Поставщиком в одностороннем порядке.
8.2. В случае изменения настоящего Договора Пользователь может отказаться от его использования, удалив свой профиль.
8.3. Если какое-либо положение настоящего Договора признано судом недействительным, это не влияет на действительность остальных положений.
8.4. Настоящий Договор регулируется и толкуется в соответствии с законодательством Российской Федерации.
8.5. Все споры, связанные с настоящим Договором, разрешаются путем переговоров между сторонами. В случае невозможности разрешения спора путем переговоров, спор подлежит разрешению в судебном порядке в соответствии с законодательством Российской Федерации.
8.6. Настоящий Договор вступает в силу с момента использования Пользователем Сервиса и действует бессрочно, до момента его отзыва Пользователем или Поставщиком.
8.7. Поставщик не несет ответственности за любые прямые или косвенные убытки, возникшие у Пользователя в связи с использованием или невозможностью использования Сервиса, включая, но не ограничиваясь, убытки, вызванные ошибками или проблемами в работе Сервиса, отказом в доступе к Сервису, несанкционированным доступом к учетным данным Пользователя, а также любыми другими проблемами, связанными с использованием Сервиса.
8.8. Поставщик не несет ответственности за любые действия или бездействия третьих лиц, связанные с использованием Сервиса Пользователем.
8.9. Настоящий Договор является полным соглашением между Поставщиком и Пользователем относительно использования Сервиса и заменяет все предыдущие или одновременные соглашения и предложения, будь то устные или письменные.
8.10. Поставщик оставляет за собой право в любое время изменять или удалять любую часть Сервиса, в том числе изменять или удалять функции, доступные только для подписчиков.
8.11. Пользователь обязуется соблюдать все применимые законы, правила и регулирования, связанные с использованием Сервиса.

9. Контактные данные
9.1. Telegram - https://t.me/Edya_support`}</p>
    </div>
  );
};

export default ContractOffer;
