import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'store/store';

import Button from '../Button';
import styles from './MainBanner.module.scss';

const MainBanner: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { first_name } = useAppSelector(state => state.user);

  return (
    <div className={styles.mainBanner}>
      <h1 className={styles.title}>edya</h1>
      <h2 className={styles.subtitle}>oкунись в мир нейросетей</h2>
      <p className={styles.description}>
        превратите свои скучные дела в интернете из унылых в потрясающие
      </p>
      <Link to={first_name ? '/dialog' : '/registration'}>
        <Button size='lg' variant='default' textButton='defaultText'>
          попробовать бесплатно
        </Button>
      </Link>
    </div>
  );
};

export default MainBanner;
