import { useEffect, useState } from 'react';
import type { Engine } from 'tsparticles-engine';
import { Particles } from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import { particlesOptions } from './constants';
import styles from './ParticlesWrap.module.scss';

const ParticlesWrap: React.FC = () => {
  const [particlesCount, setParticlesCount] = useState<number>(
    particlesOptions.particles.number.value
  );
  const particlesInit = async (engine: Engine) => {
    await loadFull(engine);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setParticlesCount(100);
      } else {
        setParticlesCount(250);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const particlesParams = particlesOptions;
  particlesParams.particles.number.value = particlesCount;

  return (
    <div className={styles.particlesWrap}>
      <Particles
        id='tsparticles'
        init={particlesInit}
        style={{ position: 'absolute', top: 0, left: 0 }}
        options={particlesOptions}
      />
    </div>
  );
};

export default ParticlesWrap;
