import ContractOffer from 'components/ContractOffer';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Line from 'components/Line';
import { SeoMetaTitles } from 'components/SeoMetaTitles';

const ContractOfferPage = () => {
  return (
    <>
      <SeoMetaTitles isComming={false} />
      <Layout>
        <Header />
        <ContractOffer />
      </Layout>
      <Line />
      <Layout>
        <Footer />
      </Layout>
    </>
  );
};

export default ContractOfferPage;
