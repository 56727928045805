import * as jose from 'jose';

import { urls } from 'store/api';
import {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  removeTokens,
} from 'utils/token';
import { GrantTypesEnum } from 'types/types';

import { apiClient } from './http';

interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
}

const secret = new TextEncoder().encode(
  process.env.REACT_APP_TELEGRAM_SITE_KEY
);

export const login = async ({ username, password, token }): Promise<void> => {
  try {
    const response = await apiClient.post(
      urls.api.auth.login.post,
      {
        username,
        password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: GrantTypesEnum.Password,
      },
      {
        headers: {
          'Captcha-Token': token,
        },
      }
    );
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
  } catch (error) {
    throw error.response.data;
  }
};

export const registration = async ({
  firstName,
  lastName,
  email,
  password,
  token,
}) => {
  const { data } = await apiClient.post<IAuthResponse>(
    urls.api.auth.register.post,
    {
      password,
      email,
      first_name: firstName,
      last_name: lastName,
    },
    {
      headers: {
        'Captcha-Token': token,
      },
    }
  );
  return data;
};

export const refreshToken = async (): Promise<void> => {
  try {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const response = await apiClient.post(urls.api.auth.login.post, {
        refresh_token: refreshToken,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: GrantTypesEnum.RefreshToken,
      });
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
    }
  } catch (error) {
    throw error.response.data;
  }
};

export const logout = (): void => {
  removeTokens();
};

export const isAuthenticated = (): boolean => {
  const accessToken = getAccessToken();
  return !!accessToken;
};

export const telegramLogin = async ({ id }) => {
  try {
    const payload = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: GrantTypesEnum.Telegram,
      telegram_id: id,
    };

    const jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(secret);

    const response = await apiClient.post(urls.telegram.auth.post, payload, {
      headers: {
        Authorization: jwt,
      },
    });

    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
    return response.data.telegram_id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const telegramRegister = async ({
  id,
  first_name,
  last_name,
}): Promise<{ telegram_id: string }> => {
  try {
    const payload = {
      first_name,
      last_name,
      telegram_id: id,
    };

    const jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(secret);

    const response = await apiClient.post(
      urls.telegram.register.post,
      payload,
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const forgotPassword = async ({ username }): Promise<void> => {
  try {
    await apiClient.post(urls.api.forgotPassword.post, {
      email: username,
      redirect_url: `${process.env.REACT_APP_BACKEND_URL}change-password/`,
    });
  } catch (error) {
    throw error.response.data;
  }
};
export const changePassword = async ({ password, token }): Promise<void> => {
  try {
    await apiClient.post(urls.api.changePassword.post, {
      password,
      token,
    });
  } catch (error) {
    throw error.response.data;
  }
};

export const sendSurvay = async ({
  name,
  company_name,
  company_description,
  email,
  purpose_of_use,
  token,
}): Promise<void> => {
  try {
    await apiClient.post(
      urls.survey.post,
      {
        name,
        company_name,
        company_description,
        email,
        purpose_of_use,
      },
      {
        headers: {
          'Captcha-Token': token,
        },
      }
    );
  } catch (error) {
    throw error.response.data;
  }
};
