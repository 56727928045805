export type TSEO = {
  isComming: boolean;
};

export const SeoMetaTitles = ({ isComming }: TSEO) => {
  return (
    <head>
      {isComming ? (
        <title>EDYA - Unofficial Midjourney API</title>
      ) : (
        <title>
          Edya GPT AI — Твой интеллектуальный помощник для эффективности,
          творчества и удобства!
        </title>
      )}
      {isComming ? (
        <meta
          name='description'
          content='Откройте новые горизонты творчества с помощью стороннего API Midjourney, позволяющего создавать уникальные изображения на основе передовых технологий искусственного интеллекта.'
        />
      ) : (
        <meta
          name='description'
          content='Edya GPT AI — твой интеллектуальный помощник и надежный друг! Получай удовольствие от мгновенных ответов на свои вопросы, реалистичной генерации изображений и многофункциональных возможностей для повышения эффективности. Открой новый уровень продуктивности, творчества и удобства с Edya GPT AI — твоим незаменимым помощником в цифровом мире!'
        />
      )}
    </head>
  );
};
