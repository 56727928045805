import Yup from 'utils/yup';

export interface IEmailState {
  username: string;
}

export const defaultValues: IEmailState = {
  username: '',
};

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email('Введите корректный email')
    .trim()
    .required('Введите email'),
});
