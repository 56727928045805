export const questions = [
  {
    question: 'Как работает Edya?',
    answer:
      'Edya использует API от OpenAI, с помощью которого происходит интеграция текстовых моделей',
  },
  {
    question: 'Как я могу использовать Edya?',
    answer:
      'Вы можете использовать Edya для поиска быстрых ответов на свои вопросы, в качестве чат-бота или же просто пообщаться на различные темы',
  },
  {
    question: 'Почему сервис платный?',
    answer:
      'Edya использует ChatGPT Plus, который является платным сервисом. Мы предоставляем доступ к этому сервису нашим пользователям. Цена нашего сервиса низкая, и мы стараемся предоставлять максимально высокое качество услуг',
  },
  {
    question: 'Какая цена на использование Edya?',
    answer: 'Минимальный тарифный план составляет 299 ₽',
  },
  {
    question: 'Какой уровень поддержки предоставляет Edya?',
    answer:
      'Мы предоставляем высококачественную поддержку для наших пользователей. Если у вас есть какие-либо вопросы или проблемы, связанные с использованием Edya, вы можете связаться с нашей службой поддержки, и мы постараемся помочь вам максимально быстро и эффективно',
  },
  {
    question: 'Как мне оплатить Edya?',
    answer:
      'Вы можете оплатить Edya с помощью дебетовой карты или платёжной системы. Мы предоставляем безопасный и удобный способ оплаты, чтобы вы могли использовать наш сервис безопасно и надежно',
  },
  {
    question: 'Как работает бесплатная версия?',
    answer: 'В бесплатной версии каждый день вам будет доступно 10 запросов',
  },
];
