export const omit = (obj: any, ...keys: string[][]) => {
  const keysToRemove = new Set(keys.flat());

  const result = { ...obj };
  keysToRemove.forEach(function (prop) {
    delete result[prop];
  });
  return result;
};

export const isString = (str: unknown): str is string => {
  if (str != null && typeof str.valueOf() === 'string') {
    return true;
  }
  return false;
};
