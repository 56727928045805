import React, { useEffect, useState } from 'react';

import { urls } from 'store/api';
import { apiClient } from 'utils/http';
import { getAccessToken } from 'utils/token';
import Loader from 'components/Loaders/Loader';
import notify from 'components/Notifications';

import Button from '../Button';
import styles from './Prices.module.scss';

interface IPriceValues {
  type: 'minimal' | 'full';
  name: string;
  requests_count: number;
  price: number;
  description: string;
}
const Prices: React.FC = () => {
  const [prices, setPrices] = useState<IPriceValues[]>([]);

  const token = getAccessToken();

  useEffect(() => {
    apiClient.get(urls.api.prices.subscription.get).then(response => {
      setPrices(response.data);
    });
  }, []);

  const choosePlan = type => {
    apiClient
      .post(urls.api.prices.subscription.post, { type, months_count: 1 })
      .then(({ data }) => {
        const link = document.createElement('a');
        link.href = data.url;
        link.target = '_blank';
        link.rel = 'noreferrer nofollow';
        link.click();
      })
      .catch(error => {
        if (!token) {
          return notify({
            type: 'warning',
            message: `${error.response.data.detail} Зарегистрируйтесь и/или авторизуйтесь`,
          });
        }
      });
  };

  return prices.length ? (
    <div className={styles.prices}>
      <p className={styles.mainTitle}>Цены</p>
      <div className={styles.pricesWrap}>
        {prices.map(price => (
          <div key={price.type} className={styles.priceCard}>
            <div className={styles.cardTitle}>
              <p className={styles.priceTitle}>{price.name}</p>
              <p className={styles.priceValue}>{price.price}₽/месяц </p>
            </div>
            <div className={styles.descriptionWrap}>
              <p className={styles.advantages}>Бонусы</p>
              {price.description.split('\n').map((item, index) => (
                <div key={index} className={styles.advantage}>
                  <img
                    className={styles.iconPrice}
                    src='./images/icons/checkMark.png'
                    alt='check-mark'
                  />
                  <p className={styles.advantageText}>{item}</p>
                </div>
              ))}

              <div className={styles.advantage}>
                <img
                  className={styles.iconPrice}
                  src='./images/icons/checkMark.png'
                  alt='check-mark'
                />
                <p className={styles.advantageText}>
                  {price.requests_count === -1
                    ? `∞ запросов`
                    : `${price.requests_count} запросов в день`}
                </p>
              </div>
            </div>
            <div className={styles.buttonWrapPrice}>
              <Button
                size='md'
                variant='default'
                textButton='priceText'
                onClick={() => choosePlan(price.type)}
              >
                выбрать этот план
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Loader size='standard' />
  );
};

export default Prices;
