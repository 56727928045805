import Yup from 'utils/yup';

export interface IPasswordState {
  password: string;
  repeatPassword: string;
}

export const defaultValues: IPasswordState = {
  password: '',
  repeatPassword: '',
};

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Введите пароль')
    .min(8)
    .matches(
      /^([!@#$%^&*()\-_=+{};:,<.>a-zA-Z0-9])*$/,
      'Пароль может содержать только латинские буквы, цифры и спецсимволы(!@#$%^&*()-_=+{};:,<.>)'
    ),
  repeatPassword: Yup.string()
    .test('isMatch', 'Пароли должны совпадать', function (value) {
      return value === this.parent.password;
    })
    .required('Введите пароль ещё раз'),
});
